
import OSS from 'ali-oss';
import * as echarts from 'echarts';
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
const getTimeStamp = (timeStr) => {
    return new Date(timeStr.split('-')[0], timeStr.split('-')[1] - 1, timeStr.split('-')[2]).getTime();
};
const DEFAULT_GRID_SYTLE = {
    left: '80px',
    right: '80px'
};
const DEFAULT_X_AXIS_LABLE = {
    show: true,
    interval: 0, // 使x轴文字显示全
    formatter: function (params) {
        var newParamsName = '';
        var paramsNameNumber = params.length;
        var provideNumber = 5; // 一行显示几个字
        var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
        for (var i = 0; i < rowNumber; i++) {
            var tempStr = '';
            for (var j = 0; j < provideNumber; j++) {
                var index = i * provideNumber + j;
                if (index < paramsNameNumber) {
                    tempStr += params[index];
                }
            }
            newParamsName += tempStr + '\n'; // 换行
        }
        return newParamsName;
    }
};
export default {
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            resetOssNum: 0,
            pickerOptions1: {},
            pickerOptions2: {},
            navForm: {
                startTime: '',
                endTime: ''
            },

            download1: false,
            download2: false,
            download3: false,
            productCategoryAmountChartBoxLoading: false,
            productCategoryImpairmentChartBoxLoading: false,
            productCategoryPieChartLoading: false
        };
    },
    mounted() {
        this.initOSSClient();
        window.addEventListener('resize', () => {
            this.chartsResize();
        });
        let today = new Date();
        today.setDate(today.getDate() - 1);
        this.navForm.startTime = '2023-09-01';
        this.navForm.endTime = formatDate(today);
        this.queryData();
    },

    methods: {
        chartsResize() {
            this.largeStyle = window.innerHeight > 714;
            setTimeout(() => {
                this.productCategoryAmountChartBox && this.productCategoryAmountChartBox.resize();
                this.productCategoryImpairmentChartBox && this.productCategoryImpairmentChartBox.resize();
                this.productCategoryPieChart && this.productCategoryPieChart.resize();
            }, 200);
        },
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        async isExistObject(name, options = {}) {
            try {
                await this.tmpOSSClient.head(name, options);
                this.fileFLag = true;
            } catch (error) {
                if (error.code === 'NoSuchKey') {
                    this.fileFLag = false;
                }
            }
        },
        downloadFile(fileKey, btnLoading) {
            if (this.tmpOSSClient) {
                this.isExistObject(fileKey);
                if (this.fileTime > 200) {
                    this.$message.error('文件下载失败，请稍后重试！');
                    return;
                }

                if (!this.fileFLag) {
                    setTimeout(() => {
                        this.fileTime++;
                        this.downloadFile(fileKey, btnLoading);
                    }, 3000);
                } else {
                    this.fileFLag = false;
                    this.fileTime = 0;
                    let fileUrl = this.tmpOSSClient.signatureUrl(fileKey);
                    this[btnLoading] = false;
                    location.href = fileUrl;
                }
            }
        },
        buildProductCategoryAmountChartBox(data = {}) {
            let chartDom = document.getElementById('productCategoryAmountChartBox');
            if (!chartDom) {
                return;
            }
            const { categories, amounts, percentages, caseCounts } = data;
            const title = {
                text: '',
                textStyle: {
                    color: '#161616',
                    fontWeight: 'bold',
                    fontSize: '12'
                },
                left: '10%'
            };
            const colors = ['#2C7BE5', '#008000', '#2979E5'];
            const legend = {
                data: [
                    { name: '索赔金额', icon: 'circle', itemStyle: { color: colors[0] } },
                    { name: '案件数', icon: 'circle', itemStyle: { color: colors[1] } },
                    {
                        name: '索赔金额占比',
                        itemStyle: { opacity: 0 },
                        lineStyle: { color: colors[2] }
                    }
                ],
                right: '10%'
            };
            const xAxis = {
                type: 'category',
                data: categories,
                axisLabel: DEFAULT_X_AXIS_LABLE
            };

            const yAxis = [
                {
                    type: 'value',
                    name: '金额（元）',
                    position: 'left',
                    splitNumber: 5
                },
                {
                    type: 'value',
                    name: '案件数',
                    position: 'right',
                    splitLine: {
                        show: false
                    }
                },
                {
                    type: 'value',
                    show: false,
                    position: 'right',
                    name: '索赔金额占比'
                }
            ];
            const series = [
                {
                    name: '索赔金额',
                    type: 'bar',
                    data: amounts,
                    yAxisIndex: 0,
                    barMaxWidth: '28px',
                    itemStyle: {
                        color: colors[0]
                    },
                    tooltip: {
                        valueFormatter: (v) => v + ' 元'
                    }
                },
                {
                    name: '案件数',
                    type: 'bar',
                    data: caseCounts,
                    yAxisIndex: 1,
                    barMaxWidth: '28px',
                    itemStyle: {
                        color: colors[1]
                    },
                    tooltip: {
                        valueFormatter: (v) => v + ' 件'
                    }
                },
                {
                    name: '索赔金额占比',
                    type: 'line',
                    data: percentages,
                    itemStyle: {
                        color: colors[2]
                    },
                    yAxisIndex: 2,
                    label: {
                        show: false,
                        formatter: (v) => `${v.value}%`
                    },
                    tooltip: {
                        valueFormatter: (v) => v + ' %'
                    }
                }
            ];

            const tooltip = {
                trigger: 'axis'
            };
            const dataZoom = [
                {
                    type: 'slider',
                    height: '15px',
                    xAxisIndex: 0,
                    filterMode: 'none',
                    bottom: 15
                }
            ];

            const option = { title, legend, xAxis, yAxis, series, tooltip, dataZoom, grid: DEFAULT_GRID_SYTLE };
            this.productCategoryAmountChartBox = echarts.init(chartDom);
            this.productCategoryAmountChartBox.clear();
            this.productCategoryAmountChartBox.setOption(option);
        },
        buildProductCategoryImpairmentChartBox(data = {}) {
            let chartDom = document.getElementById('productCategoryImpairmentChartBox');
            if (!chartDom) {
                return;
            }

            const { categories, lossAmount, decidedAmount, percentages, caseCounts } = data;

            const title = {
                text: '',
                textStyle: {
                    color: '#161616',
                    fontWeight: 'bold',
                    fontSize: '12'
                },
                left: '10%'
            };
            const legend = {
                data: [{ name: '已决金额', icon: 'circle' }, { name: '减损金额', icon: 'circle' }, { name: '已决案件数', icon: 'circle' }, { name: '减损金额占比' }],
                right: '10%'
            };
            const xAxis = {
                type: 'category',
                data: categories,
                axisLabel: DEFAULT_X_AXIS_LABLE
            };
            const yAxis = [
                {
                    type: 'value',
                    name: '金额（元）',
                    position: 'left'
                },
                {
                    type: 'value',
                    name: '案件数',
                    position: 'right',
                    splitLine: {
                        show: false
                    }
                },
                {
                    type: 'value',
                    show: false,
                    position: 'right',
                    name: '减损金额占比'
                }
            ];
            const series = [
                {
                    name: '已决金额',
                    type: 'bar',
                    data: decidedAmount,
                    yAxisIndex: 0,
                    barMaxWidth: '28px',
                    tooltip: {
                        valueFormatter: (v) => v + ' 元'
                    }
                },
                {
                    name: '减损金额',
                    type: 'bar',
                    data: lossAmount,
                    yAxisIndex: 0,
                    barMaxWidth: '28px',
                    tooltip: {
                        valueFormatter: (v) => v + ' 元'
                    }
                },
                {
                    name: '已决案件数',
                    type: 'bar',
                    data: caseCounts,
                    yAxisIndex: 1,
                    barMaxWidth: '28px',
                    tooltip: {
                        valueFormatter: (v) => v + ' 件'
                    }
                },
                {
                    name: '减损金额占比',
                    type: 'line',
                    data: percentages,
                    yAxisIndex: 2,
                    label: {
                        show: false,
                        formatter: (v) => `${v.value} %`
                    },
                    tooltip: {
                        valueFormatter: (v) => v + ' %'
                    }
                }
            ];

            const tooltip = {
                trigger: 'axis'
            };
            const dataZoom = [
                {
                    type: 'slider',
                    height: '15px',
                    xAxisIndex: 0,
                    filterMode: 'none',
                    bottom: 15
                }
            ];
            const option = { title, legend, xAxis, yAxis, series, tooltip, dataZoom, grid: DEFAULT_GRID_SYTLE };
            this.productCategoryImpairmentChartBox = echarts.init(chartDom);
            this.productCategoryImpairmentChartBox.clear();
            this.productCategoryImpairmentChartBox.setOption(option);
        },

        buildProductCategoryPieChart(data = {}) {
            let chartDom = document.getElementById('productCategoryPieChart');
            if (!chartDom) {
                return;
            }

            const { categories, decidedCaseCount, decidedCaseCountSum, decidedClaimAmount, decidedClaimAmountSum, undecidedCaseCount, undecidedCaseCountSum, undecidedClaimAmount, undecidedClaimAmountSum } = data;

            const legend = { data: categories, top: '20px' };
            const textStyle = {
                color: '#161616',
                fontWeight: 'bold',
                fontSize: '12'
            };
            const sumNumStyle = {
                fontWeight: 'bold',
                fontSize: '14',
                lineHeight: 20,
                color: '#161616'
            };
            const title = [
                { subtext: '已决案件商品分类案件数统计分析', textAlign: 'center', left: '7%', top: 70 },
                { subtext: '已决案件商品分类已决金额统计分析', textAlign: 'center', left: '32%', top: 70 },
                { subtext: '未决案件商品分类案件数统计分析', textAlign: 'center', left: '57%', top: 70 },
                { subtext: '未决案件商品分类索赔金额统计分析', textAlign: 'center', left: '82%', top: 70 },
                { subtext: `${decidedCaseCountSum || 0}\n共计`, subtextStyle: sumNumStyle, textAlign: 'center', left: '7%', top: '56%' },
                { subtext: `${decidedClaimAmountSum || 0}元\n共计`, subtextStyle: sumNumStyle, textAlign: 'center', left: '32%', top: '56%' },
                { subtext: `${undecidedCaseCountSum || 0}\n共计`, subtextStyle: sumNumStyle, textAlign: 'center', left: '57%', top: '56%' },
                { subtext: `${undecidedClaimAmountSum || 0}元\n共计`, subtextStyle: sumNumStyle, textAlign: 'center', left: '82%', top: '56%' }
            ];
            const pieStyle = {
                type: 'pie',
                avoidLabelOverlap: false,
                radius: ['45%', '60%'],
                center: ['30%', '60%'],
                label: {
                    show: false
                },
                labelLine: {
                    show: false
                }
            };
            const series = [
                {
                    name: '已决案件商品分类案件数统计分析',
                    top: 60,
                    left: 0,
                    right: '75%',
                    data: decidedCaseCount,
                    tooltip: {
                        valueFormatter: (v) => v + '件'
                    },
                    ...pieStyle
                },
                {
                    name: '已决案件商品分类已决金额统计分析',
                    top: 60,
                    left: '25%',
                    right: '50%',
                    data: decidedClaimAmount,
                    tooltip: {
                        valueFormatter: (v) => v + '元'
                    },
                    ...pieStyle
                },
                {
                    name: '未决案件商品分类案件数统计分析',
                    top: 60,
                    left: '50%',
                    right: '25%',
                    data: undecidedCaseCount,
                    tooltip: {
                        valueFormatter: (v) => v + '件'
                    },
                    ...pieStyle
                },
                {
                    name: '未决案件商品分类索赔金额统计分析',
                    top: 60,
                    left: '75%',
                    right: 0,
                    data: undecidedClaimAmount,
                    tooltip: {
                        valueFormatter: (v) => v + '元'
                    },
                    ...pieStyle
                }
            ];

            const option = {
                legend,
                series,
                title,
                tooltip: {
                    trigger: 'item'
                }
            };
            this.productCategoryPieChart = echarts.init(chartDom);
            this.productCategoryPieChart.clear();
            this.productCategoryPieChart.setOption(option);
        },
        startTimeChange() {
            this.pickerOptions2.disabledDate = (time) => {
                // 昨天
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return time.getTime() > yesterday || time.getTime() < getTimeStamp(this.navForm.startTime);
            };
        },
        endTimeChange() {
            this.pickerOptions1.disabledDate = (time) => {
                // 昨天
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return time.getTime() > getTimeStamp(this.navForm.endTime) || time.getTime() < getTimeStamp('2023-06-01');
            };
        },
        queryData() {
            this.getProductCategoryAmountChart();
            this.getProductCategoryImpairmentChart();
            this.getProductCategoryPieChart();
        },
        getProductCategoryAmountChart() {
            this.productCategoryAmountChartBoxLoading = true;
            this.instance.post('/tpa/api/dashboard/goodsCategory/goodsCategoryMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    const list = data.data.data.list;
                    this.buildProductCategoryAmountChartBox({
                        categories: list.map((e) => e.goodsCategory),
                        amounts: list.map((e) => parseFloat(e.claimAmount.replaceAll(',', ''))),
                        percentages: list.map((e) => e.claimAmountRate),
                        caseCounts: list.map((e) => e.caseCount)
                    });
                }
                this.productCategoryAmountChartBoxLoading = false;
            });
        },
        getProductCategoryImpairmentChart() {
            this.productCategoryImpairmentChartBoxLoading = true;
            this.instance.post('/tpa/api/dashboard/goodsCategory/lossAmountMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    const list = data.data.data.list;
                    this.buildProductCategoryImpairmentChartBox({
                        categories: list.map((e) => e.goodsCategory),
                        lossAmount: list.map((e) => parseFloat(e.lossAmount.replaceAll(',', ''))),
                        decidedAmount: list.map((e) => parseFloat(e.decidedAmount.replaceAll(',', ''))),
                        percentages: list.map((e) => e.lossAmountRate),
                        caseCounts: list.map((e) => e.decidedCaseCount)
                    });
                }
                this.productCategoryImpairmentChartBoxLoading = false;
            });
        },
        getProductCategoryPieChart() {
            this.productCategoryPieChartLoading = true;
            this.getPie1({});
        },
        getPie1(pieChartData) {
            this.instance.post('/tpa/api/dashboard/goodsCategory/decided/goodsCategoryCaseTop5', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    const list = data.data.data.list;
                    pieChartData['decidedCaseCount'] = list.map((e) => {
                        return { name: e.goodsCategory, value: e.caseCount };
                    });
                    pieChartData['decidedCaseCountSum'] = list[0].totalCaseCount;
                    pieChartData['categories'] = list.map((e) => e.goodsCategory);
                    this.getPie2(pieChartData);
                }
            });
        },
        getPie2(pieChartData) {
            this.instance.post('/tpa/api/dashboard/goodsCategory/decided/goodsCategoryClaimAmountTop5', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    const list = data.data.data.list;
                    pieChartData['decidedClaimAmount'] = list.map((e) => {
                        return { name: e.goodsCategory, value: e.decidedAmount.replaceAll(',', '') };
                    });
                    pieChartData['decidedClaimAmountSum'] = list[0].totalClaimAmount;
                    this.getPie3(pieChartData);
                }
            });
        },
        getPie3(pieChartData) {
            this.instance.post('/tpa/api/dashboard/goodsCategory/undecided/goodsCategoryCaseTop5', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    const list = data.data.data.list;
                    pieChartData['undecidedCaseCount'] = list.map((e) => {
                        return { name: e.goodsCategory, value: e.caseCount };
                    });
                    pieChartData['undecidedCaseCountSum'] = list[0].totalCaseCount;
                    this.getPie4(pieChartData);
                }
            });
        },
        getPie4(pieChartData) {
            this.instance.post('/tpa/api/dashboard/goodsCategory/undecided/goodsCategoryClaimAmountTop5', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    const list = data.data.data.list;
                    pieChartData['undecidedClaimAmount'] = list.map((e) => {
                        return { name: e.goodsCategory, value: e.claimAmount.replaceAll(',', '') };
                    });
                    pieChartData['undecidedClaimAmountSum'] = list[0].totalClaimAmount;
                }

                this.buildProductCategoryPieChart(pieChartData);
                this.productCategoryPieChartLoading = false;
            });
        },
        downloadXlsx(type) {
            let instanceUrl = '';
            let instanceForm = this.navForm;
            this.fileFLag = false;
            switch (type) {
                case 1:
                    instanceUrl = '/tpa/api/dashboard/goodsCategory/goodsCategoryMetrics/excel';
                    break;
                case 2:
                    instanceUrl = '/tpa/api/dashboard/goodsCategory/lossAmountMetrics/excel';
                    break;
                case 3:
                    instanceUrl = '/tpa/api/dashboard/goodsCategory/goodsCategoryCaseAndClaimAmountTop5/excel';
                    break;
                default:
                    break;
            }
            this['download' + type] = true;
            this.instance.post(instanceUrl, instanceForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.downloadFile(data.data, 'download' + type);
                } else {
                    this.$message.error(data.message);
                }
            });
        }
    }
};
