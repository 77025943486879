
import OSS from 'ali-oss';
import * as echarts from 'echarts';

const DEFAULT_TITLE_STYLE = {
    textStyle: {
        fontSize: '12px',
        color: '#3a7dbc'
    },
    left: '2%'
};
const DEFAULT_TOOLTIP_STYLE = {
    trigger: 'item',
    axisPointer: {
        type: 'cross',
        label: {
            backgroundColor: '#6a7985'
        }
    }
};
const DEFAULT_GRID_SYTLE = {
    left: '50px',
    right: '50px'
};
export default {
    data() {
        return {
            resetOssNum: 0,
            navForm: {
                startTime: '',
                endTime: ''
            },
            pickerOptions1: {},
            pickerOptions2: {},
            activeName: '1',
            activeTemporalDimensionName: 'inflowTime',

            handlers: [],
            handlerValues: ['李小康', '张珂健', '袁其彪'],
            preHandlers: [],
            preHandlerValues: ['李小康', '张珂健', '袁其彪'],
            // 每周作业人员
            caseWeekInterfaceData: [],
            caseWeekTitle: '',
            caseWeekHandlerValues: ['李小康', '张珂健', '袁其彪'],
            caseWeekHandlers: [],
            preCaseWeekInterfaceData: [],
            preCaseWeekTitle: '',
            preCaseWeekHandlerValues: ['李小康', '张珂健', '袁其彪'],
            preCaseWeekHandlers: [],
            // 每月作业人员
            caseMonthInterfaceData: [],
            caseMonthTitle: '',
            caseMonthHandlerValues: ['李小康', '张珂健', '袁其彪'],
            caseMonthHandlers: [],
            preCaseMonthInterfaceData: [],
            preCaseMonthTitle: '',
            preCaseMonthHandlerValues: ['李小康', '张珂健', '袁其彪'],
            // preCaseMonthHandlerValues: ['俞乾'],
            preCaseMonthHandlers: [],

            download0: false,
            download1: false,
            download2: false,
            download3: false,
            download4: false,
            download5: false,
            download6: false,
            download9901: false,
            download9902: false,
            download9903: false,
            download9904: false,

            loadingChart1: false,
            loadingChart2: false,
            loadingChart3: false,
            loadingChart4: false,
            loadingChart5: false,
            loadingChart6: false,
            loadingChart7: false,
            loadingChart8: false,
            loadingChart9: false,
            loadingChart10: false,
            loadingChart11: false,
            loadingChart12: false,
            loadingChart13: false,
            loadingChart14: false,
            loadingChart15: false,
            loadingChart16: false,
            loadingChart17: false,
            officialCaseDailyMetrics: '',
            preCaseDailyMetrics: '',
            tmpOSSClient: null,
            // 标题
            officialCaseClosingTitle: '',
            preCaseClosingTitle: '',
            officialCaseLossAmountTitle: '',
            officialCaseAvgLossAmountTitle: '',

            officialCaseClosingTimeMetricsByDecidedTimeTitle: '',
            preCaseClosingTimeMetricsByDecidedTimeTitle: '',
            officialCaseLossAmountMetricsByDecidedTimeTitle: '',
            officialCaseAvgLossAmountMetricsByDecidedTimeTitle: ''
        };
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.chartsResize();
        });

        this.initOSSClient();
        let today = new Date();
        today.setDate(today.getDate() - 1);
        this.navForm.startTime = '2023-09-01';
        this.navForm.endTime = this.formatDate(today);

        this.pickerOptions1.disabledDate = (time) => {
            // 昨天
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return time.getTime() > yesterday || time.getTime() < this.getTimeStamp('2023-06-01');
        };
        this.pickerOptions2.disabledDate = (time) => {
            // 昨天
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return time.getTime() > yesterday || time.getTime() < this.getTimeStamp('2023-06-01');
        };

        this.queryData();
    },
    methods: {
        chartsResize() {
            this.largeStyle = window.innerHeight > 714;
            this.$nextTick(() => {
                this.officialCaseMetricsCharts && this.officialCaseMetricsCharts.resize();
                this.undecidedCaseMetricsCharts && this.undecidedCaseMetricsCharts.resize();
                this.officialCaseDailyMetricsCharts && this.officialCaseDailyMetricsCharts.resize();
                this.preCaseDailyMetricsCharts && this.preCaseDailyMetricsCharts.resize();
                this.caseWeekCharts1 && this.caseWeekCharts1.resize();
                this.caseMonthCharts3 && this.caseMonthCharts3.resize();

                this.officialCaseClosingTimeMetricsCharts && this.officialCaseClosingTimeMetricsCharts.resize();
                this.preCaseClosingTimeMetricsCharts && this.preCaseClosingTimeMetricsCharts.resize();
                this.officialCaseLossAmountMetricsCharts && this.officialCaseLossAmountMetricsCharts.resize();
                this.officialCaseAvgLossAmountMetricsCharts && this.officialCaseAvgLossAmountMetricsCharts.resize();
            });
        },
        startTimeChange() {
            this.pickerOptions2.disabledDate = (time) => {
                // 昨天
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return time.getTime() > yesterday || time.getTime() < this.getTimeStamp(this.navForm.startTime);
            };
        },
        endTimeChange() {
            this.pickerOptions1.disabledDate = (time) => {
                // 昨天
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return time.getTime() > this.getTimeStamp(this.navForm.endTime) || time.getTime() < this.getTimeStamp('2023-06-01');
            };
        },

        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getTimeStamp(timeStr) {
            return new Date(timeStr.split('-')[0], timeStr.split('-')[1] - 1, timeStr.split('-')[2]).getTime();
        },
        handleClick(tab, event) {
            this.$nextTick(() => {
                this.tabClickQuery();
            });
        },

        handleTemporalDimensionClick(tab, event) {
            this.$nextTick(() => {
                this.tabHandleTemporalDimensionClickClickQuery();
            });
        },

        tabHandleTemporalDimensionClickClickQuery() {
            switch (this.activeTemporalDimensionName) {
                case 'inflowTime':
                    this.getOfficialCaseClosingTimeMetricsCharts();
                    this.getPreCaseClosingTimeMetricsCharts();
                    this.getOfficialCaseLossAmountMetricsCharts();
                    this.getOfficialCaseAvgLossAmountMetricsCharts();
                    break;
                case 'closeTime':
                    console.log('closeTime');
                    this.getOfficialCaseClosingTimeMetricsByDecidedTimeCharts();
                    this.getPreCaseClosingTimeMetricsByDecidedTimeCharts();
                    this.getOfficialCaseLossAmountMetricsByDecidedTimeCharts();
                    this.getOfficialCaseAvgLossAmountMetricsByDecidedTimeCharts();
                    break;
            }
        },

        tabClickQuery() {
            switch (this.activeName) {
                case '1':
                    this.getOfficialCaseMetrics();
                    this.getUndecidedCaseMetrics();
                    this.getPreReportCaseMetricsCharts();
                    break;
                case '2':
                    this.getOfficialCaseDailyMetricsCharts();
                    this.getPreCaseDailyMetricsCharts();
                    break;
                case '3':
                    this.getCaseDailyMetricsChartsWeek(3);
                    this.getPreCaseDailyMetricsChartsWeek(3);
                    break;
                case '4':
                    this.getCaseDailyMetricsChartsWeek(4);
                    this.getPreCaseDailyMetricsChartsWeek(4);
                    break;
            }
        },

        getOfficialCaseMetrics() {
            this.loadingChart1 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/officialCaseMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.setOfficialCaseMetricsCharts(data.data);
                    this.loadingChart1 = false;
                }
            });
        },

        setOfficialCaseMetricsCharts(response) {
            let chartDom = document.getElementById('officialCaseMetricsCharts');
            if (!chartDom) {
                return;
            }
            this.officialCaseMetricsCharts = echarts.init(chartDom);
            let yData = [];

            let decidedCaseCount = [];
            let undecidedCaseCount = [];
            let assignmentCaseCount = [];
            let color = ['#2C7BE4', '#5DAF33'];

            response.data.list.forEach((item) => {
                yData.push(item.handler);
                decidedCaseCount.push(item.decidedCaseCount);
                undecidedCaseCount.push(item.undecidedCaseCount);
                assignmentCaseCount.push(item.assignmentCaseCount);
            });
            yData = yData.reverse();
            decidedCaseCount = decidedCaseCount.reverse();
            undecidedCaseCount = undecidedCaseCount.reverse();
            assignmentCaseCount = assignmentCaseCount.reverse();

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -15
                    }
                },
                grid: {
                    left: '3%',
                    top: '60px',
                    right: '4%',
                    bottom: '0px',
                    containLabel: true
                },
                legend: {
                    top: '15px'
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: [
                    {
                        name: '作业人员',
                        type: 'category',
                        data: yData
                    },
                    {
                        name: '接案量',
                        type: 'category',
                        data: assignmentCaseCount
                    }
                ],
                series: [
                    {
                        name: '已决案件',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: decidedCaseCount
                    },
                    {
                        name: '未决案件',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: undecidedCaseCount
                    }
                ]
            };
            this.officialCaseMetricsCharts.setOption(option);
        },

        getUndecidedCaseMetrics() {
            this.loadingChart2 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/undecidedCaseMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.setUndecidedCaseMetricsCharts(data.data);
                    this.loadingChart2 = false;
                }
            });
        },

        setUndecidedCaseMetricsCharts(response) {
            let chartDom = document.getElementById('undecidedCaseMetricsCharts');
            if (!chartDom) {
                return;
            }
            this.undecidedCaseMetricsCharts = echarts.init(chartDom);
            let yData = [];

            let lossAssessmentCaseCount = [];
            let undecidedCaseCount = [];
            let surveyingCaseCount = [];
            let unAdjustCaseCount = [];
            let unSurveyedCaseCount = [];
            let undeterminedCaseCount = [];
            let color = ['#2C7BE4', '#5DAF33', '#FAC859', '#EE6666', '#09abeb'];

            response.data.list.forEach((item) => {
                yData.push(item.handler);
                lossAssessmentCaseCount.push(item.lossAssessmentCaseCount);
                undecidedCaseCount.push(item.undecidedCaseCount);
                surveyingCaseCount.push(item.surveyingCaseCount);
                unAdjustCaseCount.push(item.unAdjustCaseCount);
                unSurveyedCaseCount.push(item.unSurveyedCaseCount);
                undeterminedCaseCount.push(item.undeterminedCaseCount);
            });
            yData = yData.reverse();
            lossAssessmentCaseCount = lossAssessmentCaseCount.reverse();
            undecidedCaseCount = undecidedCaseCount.reverse();
            surveyingCaseCount = surveyingCaseCount.reverse();
            unAdjustCaseCount = unAdjustCaseCount.reverse();
            unSurveyedCaseCount = unSurveyedCaseCount.reverse();
            undeterminedCaseCount = undeterminedCaseCount.reverse();

            let option = {
                title: {
                    subtextStyle: {
                        fontSize: 8,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -10
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                legend: {},
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: [
                    {
                        name: '作业人员',
                        type: 'category',
                        data: yData
                    },
                    {
                        name: '未决案件量',
                        type: 'category',
                        data: undecidedCaseCount
                    }
                ],
                series: [
                    {
                        name: '待定损',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: undeterminedCaseCount
                    },
                    {
                        name: '待查勘',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: unSurveyedCaseCount
                    },
                    {
                        name: '查勘中',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: surveyingCaseCount
                    },
                    {
                        name: '核损中',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: lossAssessmentCaseCount
                    },
                    {
                        name: '待核赔',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: unAdjustCaseCount
                    }
                ]
            };

            this.undecidedCaseMetricsCharts.setOption(option);
        },

        getPreReportCaseMetricsCharts() {
            this.loadingChart3 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/preReportCaseMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.setPreReportCaseMetricsCharts(data.data);
                    this.loadingChart3 = false;
                }
            });
        },

        setPreReportCaseMetricsCharts(response) {
            let chartDom = document.getElementById('preReportCaseMetricsCharts');
            if (!chartDom) {
                return;
            }
            this.preReportCaseMetricsCharts = echarts.init(chartDom);
            let yData = [];

            let caseCount = [];
            let completedCaseCount = [];
            let preLossCaseCount = [];
            let color = ['#2C7BE4', '#5DAF33'];

            response.data.list.forEach((item) => {
                yData.push(item.handler);
                caseCount.push(item.caseCount);
                completedCaseCount.push(item.completedCaseCount);
                preLossCaseCount.push(item.preLossCaseCount);
            });
            yData = yData.reverse();
            caseCount = caseCount.reverse();
            completedCaseCount = completedCaseCount.reverse();
            preLossCaseCount = preLossCaseCount.reverse();

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -15
                    }
                },
                grid: {
                    left: '3%',
                    top: '60px',
                    right: '4%',
                    bottom: '0px',
                    containLabel: true
                },
                legend: {
                    top: '15px'
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: [
                    {
                        name: '作业人员',
                        type: 'category',
                        data: yData
                    },
                    {
                        name: '接案量',
                        type: 'category',
                        data: caseCount
                    }
                ],
                series: [
                    {
                        name: '预定损案件',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: preLossCaseCount
                    },
                    {
                        name: '已完成案件',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: completedCaseCount
                    }
                ]
            };
            this.preReportCaseMetricsCharts.setOption(option);
        },

        getOfficialCaseClosingTimeMetricsCharts() {
            this.loadingChart4 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/officialCaseClosingTimeMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.officialCaseClosingTitle = data.data.title;
                    this.setOfficialCaseClosingTimeMetricsCharts(data.data);
                    this.loadingChart4 = false;
                }
            });
        },

        setOfficialCaseClosingTimeMetricsCharts(response) {
            let chartDom = document.getElementById('officialCaseClosingTimeMetricsCharts');
            if (!chartDom) {
                return;
            }
            this.officialCaseClosingTimeMetricsCharts = echarts.init(chartDom);
            let xData = [];

            let avgClosingTime = [];
            let closingCaseCount = [];
            let closingRate = [];
            let color = ['#2C7BE4', '#5DAF33', '#EF9A20'];

            response.data.list.forEach((item) => {
                xData.push(item.handler);
                avgClosingTime.push(item.avgClosingTime);
                closingCaseCount.push(item.closingCaseCount);
                closingRate.push(item.closingRate);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -15
                    }
                },
                grid: {
                    left: '3%',
                    top: '60px',
                    right: '4%',
                    bottom: '0px',
                    containLabel: true
                },
                legend: {
                    top: '15px'
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisLabel: {
                        show: true,
                        margin: 10,
                        rotate: 20
                    }
                },
                yAxis: [
                    {
                        name: '结案量',
                        type: 'value'
                    },
                    {
                        name: '案均时效(H)',
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '结案量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: closingCaseCount
                    },
                    {
                        name: '案均结案时效(H)',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + 'H';
                            }
                        },
                        data: avgClosingTime
                    },
                    {
                        name: '结案率(%)',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '%';
                            }
                        },
                        data: closingRate
                    }
                ]
            };
            this.officialCaseClosingTimeMetricsCharts.setOption(option);
        },

        getPreCaseClosingTimeMetricsCharts() {
            this.loadingChart5 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/preCaseClosingTimeMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.preCaseClosingTitle = data.data.title;
                    this.setPreCaseClosingTimeMetricsCharts(data.data);
                    this.loadingChart5 = false;
                }
            });
        },

        setPreCaseClosingTimeMetricsCharts(response) {
            let chartDom = document.getElementById('preCaseClosingTimeMetricsCharts');
            if (!chartDom) {
                return;
            }
            this.preCaseClosingTimeMetricsCharts = echarts.init(chartDom);
            let xData = [];

            let avgClosingTime = [];
            let completedCaseCount = [];
            let completedRate = [];
            let color = ['#2C7BE4', '#5DAF33', '#EF9A20'];

            response.data.list.forEach((item) => {
                xData.push(item.handler);
                avgClosingTime.push(item.avgClosingTime);
                completedCaseCount.push(item.completedCaseCount);
                completedRate.push(item.completedRate);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -15
                    }
                },
                grid: {
                    left: '3%',
                    top: '60px',
                    right: '4%',
                    bottom: '0px',
                    containLabel: true
                },
                legend: {
                    top: '15px'
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisLabel: {
                        show: true,
                        margin: 10,
                        rotate: 20
                    }
                },
                yAxis: [
                    {
                        name: '完成量',
                        type: 'value'
                    },
                    {
                        name: '完成时效(H)',
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '完成量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: completedCaseCount
                    },
                    {
                        name: '案均完成时效(H)',
                        type: 'line',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + 'H';
                            }
                        },
                        data: avgClosingTime
                    },
                    {
                        name: '完成率(%)',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '%';
                            }
                        },
                        data: completedRate
                    }
                ]
            };
            this.preCaseClosingTimeMetricsCharts.setOption(option);
        },

        getOfficialCaseLossAmountMetricsCharts() {
            this.loadingChart6 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/officialCaseLossAmountMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.officialCaseLossAmountTitle = data.data.title;
                    this.setOfficialCaseLossAmountMetricsCharts(data.data);
                    this.loadingChart6 = false;
                }
            });
        },

        setOfficialCaseLossAmountMetricsCharts(response) {
            let chartDom = document.getElementById('officialCaseLossAmountMetricsCharts');
            if (!chartDom) {
                return;
            }
            this.officialCaseLossAmountMetricsCharts = echarts.init(chartDom);
            let xData = [];

            let lossAmount = [];
            let lossRate = [];
            let color = ['#2C7BE4', '#EF9A20'];

            response.data.list.forEach((item) => {
                xData.push(item.handler);
                lossAmount.push(item.lossAmount.replace(/,/g, ''));
                lossRate.push(item.lossRate);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -15
                    }
                },
                grid: {
                    left: '3%',
                    top: '60px',
                    right: '4%',
                    bottom: '0px',
                    containLabel: true
                },
                legend: {
                    top: '15px'
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisLabel: {
                        show: true,
                        margin: 10,
                        rotate: 20
                    }
                },
                yAxis: [
                    {
                        name: '减损金额(元)',
                        type: 'value'
                    },
                    {
                        name: '减损率(%)',
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '减损金额(元)',
                        type: 'bar',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: lossAmount
                    },
                    {
                        name: '减损率(%)',
                        type: 'line',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '%';
                            }
                        },
                        data: lossRate
                    }
                ]
            };
            this.officialCaseLossAmountMetricsCharts.setOption(option);
        },

        getOfficialCaseAvgLossAmountMetricsCharts() {
            this.loadingChart7 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/officialCaseAvgLossAmountMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.officialCaseAvgLossAmountTitle = data.data.title;
                    this.setOfficialCaseAvgLossAmountMetricsCharts(data.data);
                    this.loadingChart7 = false;
                }
            });
        },

        setOfficialCaseAvgLossAmountMetricsCharts(response) {
            let chartDom = document.getElementById('officialCaseAvgLossAmountMetricsCharts');
            if (!chartDom) {
                return;
            }
            this.officialCaseAvgLossAmountMetricsCharts = echarts.init(chartDom);
            let xData = [];

            let avgLossAmount = [];
            let avgLossRate = [];
            let color = ['#2C7BE4', '#EF9A20'];

            response.data.list.forEach((item) => {
                xData.push(item.handler);
                avgLossAmount.push(item.avgLossAmount.replace(/,/g, ''));
                avgLossRate.push(item.avgLossRate);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -15
                    }
                },
                grid: {
                    left: '3%',
                    top: '60px',
                    right: '4%',
                    bottom: '0px',
                    containLabel: true
                },
                legend: {
                    top: '15px'
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisLabel: {
                        show: true,
                        margin: 10,
                        rotate: 20
                    }
                },
                yAxis: [
                    {
                        name: '案均减损金额(元)',
                        type: 'value'
                    },
                    {
                        name: '案均减损率(%)',
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '案均减损金额(元)',
                        type: 'bar',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: avgLossAmount
                    },
                    {
                        name: '减损率(%)',
                        type: 'line',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '%';
                            }
                        },
                        data: avgLossRate
                    }
                ]
            };
            this.officialCaseAvgLossAmountMetricsCharts.setOption(option);
        },

        getOfficialCaseDailyMetricsCharts() {
            this.loadingChart8 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/officialCaseDailyMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.handlers = [];
                    data.data.data.list.forEach((item) => {
                        this.handlers.push({ value: item.handler });
                    });
                    this.officialCaseDailyMetrics = data.data;
                    this.setOfficialCaseDailyMetricsCharts(data.data);
                    this.loadingChart8 = false;
                }
            });
        },

        onChangeOption(chartType, item) {
            this.$nextTick(() => {
                switch (chartType) {
                    case 1:
                        this.setOfficialCaseDailyMetricsCharts(this.officialCaseDailyMetrics);
                        break;
                    case 2:
                        this.setPreCaseDailyMetricsCharts(this.preCaseDailyMetrics);
                        break;
                    case 3:
                        // 设置每周正式报案作业量
                        this.setCaseChartOfWeek();
                        break;
                    case 4:
                        // 设置每周预报案作业量
                        this.setPreCaseChartOfWeek();
                        break;
                    case 5:
                        // 设置每月正式报案作业量
                        this.setCaseChartOfMonth();
                        break;
                    case 6:
                        // 设置每月预报案作业量
                        this.setPreCaseChartOfMonth();
                        break;

                    default:
                        break;
                }
            });
        },

        setOfficialCaseDailyMetricsCharts(response) {
            let chartDom = document.getElementById('officialCaseDailyMetricsCharts');
            if (!chartDom) {
                return;
            }
            this.officialCaseDailyMetricsCharts = echarts.init(chartDom);
            this.officialCaseDailyMetricsCharts.clear();
            let xData = [];
            let series = [];
            let color = ['#2C7BE4', '#5DAF33', '#EF9A20'];

            let handlerDataObj = {};
            this.handlerValues.forEach((item) => {
                handlerDataObj[item] = {};
                let assignmentCaseCount = [];
                let decidedCaseCount = [];
                let undecidedCaseCount = [];
                let result = response.data.list.find((obj) => obj.handler.includes(item));
                result.data.forEach((item2) => {
                    xData.push(item2.date);
                    assignmentCaseCount.push(item2.assignmentCaseCount);
                    decidedCaseCount.push(item2.decidedCaseCount);
                    undecidedCaseCount.push(item2.undecidedCaseCount);

                    // {李小康：{2023-09-19:[接案量，已决，未决]}}
                    handlerDataObj[item][item2.date] = [];
                    handlerDataObj[item][item2.date].push(item2.assignmentCaseCount);
                    handlerDataObj[item][item2.date].push(item2.decidedCaseCount);
                    handlerDataObj[item][item2.date].push(item2.undecidedCaseCount);
                });
                xData = [...new Set(xData)];
                series.push({
                    name: item,
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: assignmentCaseCount
                });
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -10
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                },
                legend: {},
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: (params, a, b, c) => {
                        let tr = '';
                        let html = '';
                        let currentHandlerData = null;
                        for (let i = 0; i < params.length; i++) {
                            currentHandlerData = handlerDataObj[params[i].seriesName][params[i].axisValue];
                            tr += `<tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[i]}"></span></td><td>${params[i].seriesName}</td><td width="20px"></td><td style="text-align:center;">${currentHandlerData[0]}</td><td style="text-align:center;">${currentHandlerData[1]}</td><td style="text-align:center;">${currentHandlerData[2]}</td></tr>`;
                        }
                        html =
                            `
                            <div>
                                <span>${params[0].axisValue}</span>
                                <hr>
                                 <table>
                                     <tr height="20px"><td width="10px"></td><td width="40px" style="text-align:center;">作业人</td><td width="20px"></td><td width="60px" style="text-align:center;">今日接案量</td><td width="120px" style="text-align:center;">今日决案量</td><td width="120px" style="text-align:center;">今日接案未决案件量</td></tr>
                                     ` +
                            tr +
                            `
                                 </table>
                            </div>
                        `;
                        return html;
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData
                },
                yAxis: [
                    {
                        name: '接案量',
                        type: 'value'
                    }
                ],
                series: series,
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: 20
                    }
                ]
            };
            this.officialCaseDailyMetricsCharts.setOption(option);
        },

        getPreCaseDailyMetricsCharts() {
            this.loadingChart9 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/preCaseDailyMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.preHandlers = [];
                    data.data.data.list.forEach((item) => {
                        this.preHandlers.push({ value: item.handler });
                    });
                    this.preCaseDailyMetrics = data.data;
                    this.setPreCaseDailyMetricsCharts(data.data);
                    this.loadingChart9 = false;
                }
            });
        },
        getCaseDailyMetricsChartsWeek(activeName) {
            this.loadingChart10 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/officialCaseMonthlyMetrics', this.navForm).then((res) => {
                let data = res.data;
                this.loadingChart10 = false;
                if (data.code === 200) {
                    // 设置每周作业人员选择器

                    this.caseWeekInterfaceData = data.data.data.list;
                    this.caseWeekTitle = data.data.title;

                    this.caseWeekHandlers = [];
                    data.data.data.list.forEach((item) => {
                        this.caseWeekHandlers.push({ value: item.handler });
                    });
                    this.caseWeekHandlers = [...new Set(this.caseWeekHandlers)];

                    // 设置每月作业人员选择器

                    this.caseMonthInterfaceData = data.data.data.list;
                    this.caseMonthTitle = data.data.title;

                    this.caseMonthHandlers = [];
                    data.data.data.list.forEach((item) => {
                        this.caseMonthHandlers.push({ value: item.handler });
                    });
                    this.caseMonthHandlers = [...new Set(this.caseMonthHandlers)];

                    if (activeName == 3) {
                        // 设置每周正式报案作业量
                        this.setCaseChartOfWeek();
                    } else {
                        // 设置每月预报案作业量
                        this.setCaseChartOfMonth();
                    }
                }
            });
        },
        getPreCaseDailyMetricsChartsWeek(activeName) {
            this.loadingChart11 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/preCaseMonthlyMetrics', this.navForm).then((res) => {
                let data = res.data;
                this.loadingChart11 = false;
                if (data.code === 200) {
                    // 每周作业人员选择器
                    this.preCaseWeekInterfaceData = data.data.data.list;
                    this.preCaseWeekTitle = data.data.title;

                    this.preCaseWeekHandlers = [];
                    data.data.data.list.forEach((item) => {
                        this.preCaseWeekHandlers.push({ value: item.handler });
                    });
                    this.preCaseWeekHandlers = [...new Set(this.preCaseWeekHandlers)];

                    // 每月作业人员选择器
                    this.preCaseMonthInterfaceData = data.data.data.list;
                    this.preCaseMonthTitle = data.data.title;

                    this.preCaseMonthHandlers = [];
                    data.data.data.list.forEach((item) => {
                        this.preCaseMonthHandlers.push({ value: item.handler });
                    });
                    this.preCaseMonthHandlers = [...new Set(this.preCaseMonthHandlers)];

                    if (activeName == 3) {
                        // 设置每周预报案作业量
                        this.setPreCaseChartOfWeek();
                    } else {
                        // 设置每月预报案作业量
                        this.setPreCaseChartOfMonth();
                    }
                }
            });
        },
        setCaseChartOfWeek() {
            // 筛选作业人员数据
            // 接口数据 筛选人员
            let response = this.setSelectHandlerData(this.caseWeekInterfaceData, this.caseWeekHandlerValues);
            // 处理直接绘制图表数据
            let chartData = this.handleWeekData(response, 'case');

            this.$nextTick(() => {
                let chartDom1 = document.getElementById('caseWeekCharts1');
                if (!chartDom1) {
                    return;
                }
                this.caseWeekCharts1 = echarts.init(chartDom1);
                this.renderCaseBarChartOfWeek(chartData, this.caseWeekTitle, this.caseWeekCharts1, chartData.maxWeekNum);
            });
        },
        setPreCaseChartOfWeek() {
            // 筛选作业人员数据
            // 接口数据 筛选人员
            let response = this.setSelectHandlerData(this.preCaseWeekInterfaceData, this.preCaseWeekHandlerValues);
            // 处理直接绘制图表数据
            let chartData = this.handleWeekData(response, 'preCase');

            this.$nextTick(() => {
                let chartDom2 = document.getElementById('preCaseWeekCharts2');
                if (!chartDom2) {
                    return;
                }
                this.caseWeekCharts2 = echarts.init(chartDom2);
                this.renderCaseBarChartOfWeek(chartData, this.preCaseWeekTitle, this.caseWeekCharts2, chartData.maxWeekNum);
            });
        },
        setCaseChartOfMonth() {
            // 筛选作业人员数据
            // 接口数据 筛选人员
            let response = this.setSelectHandlerData(this.caseMonthInterfaceData, this.caseMonthHandlerValues);
            // 处理直接绘制图表数据
            let chartData = this.handleMonthData(response, 'case');

            this.$nextTick(() => {
                let chartDom3 = document.getElementById('caseMonthCharts3');
                if (!chartDom3) {
                    return;
                }
                this.caseMonthCharts3 = echarts.init(chartDom3);
                this.renderCaseBarChartOfMonth(chartData, this.caseMonthTitle, this.caseMonthCharts3, chartData.maxWeekNum);
            });
        },
        setPreCaseChartOfMonth() {
            // 筛选作业人员数据
            // 接口数据 筛选人员
            let response = this.setSelectHandlerData(this.preCaseMonthInterfaceData, this.preCaseMonthHandlerValues);
            // 处理直接绘制图表数据
            let chartData = this.handleMonthData(response, 'preCase');

            this.$nextTick(() => {
                let chartDom4 = document.getElementById('preCaseMonthCharts2');
                if (!chartDom4) {
                    return;
                }
                this.caseMounthCharts4 = echarts.init(chartDom4);
                this.renderCaseBarChartOfMonth(chartData, this.caseWeekTitle, this.caseMounthCharts4);
            });
        },
        onChangePreOption() {
            this.setPreCaseDailyMetricsCharts(this.preCaseDailyMetrics);
        },
        setSelectHandlerData(interFaceData, selectHandler) {
            let findResData = [];
            selectHandler.forEach((item) => {
                let result = interFaceData.find((obj) => obj.handler.includes(item));
                findResData.push(result);
            });

            return findResData;
        },
        handleWeekData(response, chartType) {
            let xAxisData = [];
            let maxWeekNum = 'null';
            let maxWeekNumArr = [];
            let tmpSeries = [];
            let weekSeriesObj = {};
            let handlerStackArr = [];
            if (response && response.length > 0 && response[0] && response[0].data) {
                response[0].data.forEach((item, index) => {
                    xAxisData.push(item.date);
                });

                // 找出最大周 && 处理成 人-月-周 对应数据对象
                response.forEach((item, index) => {
                    weekSeriesObj[item.handler] = {};
                    item.handler && handlerStackArr.push(item.handler);
                    item.data.forEach((dataItem, dataIndex) => {
                        if (chartType == 'case') {
                            weekSeriesObj[item.handler][dataItem.date] = {
                                connection: [],
                                decidedCaseCount: [],
                                undecidedCaseCount: []
                            };
                        } else {
                            weekSeriesObj[item.handler][dataItem.date] = {
                                connection: [],
                                preLossCaseCount: [],
                                completedCaseCount: []
                            };
                        }
                        if (dataItem.weekData) {
                            maxWeekNumArr.push(dataItem.weekData.length);
                            dataItem.weekData.forEach((weekItem, weekIndex) => {
                                if (chartType == 'case') {
                                    // 接案量
                                    weekSeriesObj[item.handler][dataItem.date].connection.push(weekItem.assignmentCaseCount || 0);
                                    // 已决
                                    weekSeriesObj[item.handler][dataItem.date].decidedCaseCount.push(weekItem.decidedCaseCount || 0);
                                    // 未决
                                    weekSeriesObj[item.handler][dataItem.date].undecidedCaseCount.push(weekItem.undecidedCaseCount || 0);
                                } else {
                                    // 接案量
                                    weekSeriesObj[item.handler][dataItem.date].connection.push(weekItem.assignmentCaseCount || 0);
                                    // 预定损
                                    weekSeriesObj[item.handler][dataItem.date].preLossCaseCount.push(weekItem.preLossCaseCount || 0);
                                    // 已完成
                                    weekSeriesObj[item.handler][dataItem.date].completedCaseCount.push(weekItem.completedCaseCount || 0);
                                }
                            });
                        }
                    });
                });
                maxWeekNum = Math.max(...maxWeekNumArr);

                // 补全周数据
                for (const key in weekSeriesObj) {
                    let nameObj = weekSeriesObj[key];
                    for (const dateKey in nameObj) {
                        let difference = maxWeekNum - nameObj[dateKey].connection.length > 0;
                        if (difference > 0) {
                            for (let i = 0; i < difference; i++) {
                                if (chartType == 'case') {
                                    nameObj[dateKey].connection.push(0);
                                    nameObj[dateKey].decidedCaseCount.push(0);
                                    nameObj[dateKey].undecidedCaseCount.push(0);
                                } else {
                                    nameObj[dateKey].connection.push(0);
                                    nameObj[dateKey].preLossCaseCount.push(0);
                                    nameObj[dateKey].completedCaseCount.push(0);
                                }
                            }
                        }
                    }
                }

                // 拼接图例基础数据
                let seriesDataObj = {};
                handlerStackArr.forEach((item) => {
                    let seriesData = [];
                    for (let i = 0; i < maxWeekNum; i++) {
                        seriesData.push({
                            name: `第${i + 1}周`,
                            type: 'bar',
                            barMaxWidth: '28px',
                            stack: item,
                            data: []
                        });
                    }
                    seriesDataObj[item] = { data: seriesData };
                });

                // 组合最终展示数据
                // weekSeriesObj = {人:{2023-09:[1-n周具体数]}}   n = maxWeekNum 最大周
                for (const key in weekSeriesObj) {
                    let nameObj = weekSeriesObj[key];
                    // nameObj = {2023-09:[1-n周具体数]}   n = maxWeekNum 最大周
                    for (const dateKey in nameObj) {
                        // xAxisData = [2023-09,2023-10]
                        xAxisData.forEach((dateItem) => {
                            if (dateItem == dateKey) {
                                nameObj[dateKey].connection.forEach((item, index) => {
                                    seriesDataObj[key].data[index].data.push({ value: item, customAttr: key });
                                });
                            }
                        });
                    }
                }

                for (const key in seriesDataObj) {
                    tmpSeries = tmpSeries.concat(seriesDataObj[key].data);
                }
            }
            return {
                xAxisData: xAxisData,
                maxWeekNum: maxWeekNum,
                chartType: chartType,
                weekSeriesObj: weekSeriesObj,
                tmpSeries: tmpSeries
            };
        },
        handleMonthData(response, chartType) {
            let xAxisData = [];
            let maxWeekNum = 'null';
            let maxWeekNumArr = [];
            let tmpSeries = [];
            let monthSeriesObj = {};
            let handlerStackArr = [];
            if (response && response.length > 0 && response[0] && response[0].data) {
                response[0].data.forEach((item, index) => {
                    xAxisData.push(item.date);
                });

                // 找出最大周 && 处理成 人-月-周 对应数据对象
                response.forEach((item, index) => {
                    monthSeriesObj[item.handler] = {};
                    item.handler && handlerStackArr.push(item.handler);
                    item.data.forEach((dataItem, dataIndex) => {
                        if (chartType == 'case') {
                            monthSeriesObj[item.handler][dataItem.date] = {
                                connection: [],
                                decidedCaseCount: [],
                                undecidedCaseCount: []
                            };
                        } else {
                            monthSeriesObj[item.handler][dataItem.date] = {
                                connection: [],
                                preLossCaseCount: [],
                                completedCaseCount: []
                            };
                        }

                        if (chartType == 'case') {
                            // 接案量
                            monthSeriesObj[item.handler][dataItem.date].connection.push(dataItem.assignmentCaseCount || 0);
                            // 已决
                            monthSeriesObj[item.handler][dataItem.date].decidedCaseCount.push(dataItem.decidedCaseCount || 0);
                            // 未决
                            monthSeriesObj[item.handler][dataItem.date].undecidedCaseCount.push(dataItem.undecidedCaseCount || 0);
                        } else {
                            // 接案量
                            monthSeriesObj[item.handler][dataItem.date].connection.push(dataItem.assignmentCaseCount || 0);
                            // 预定损
                            monthSeriesObj[item.handler][dataItem.date].preLossCaseCount.push(dataItem.preLossCaseCount || 0);
                            // 已完成
                            monthSeriesObj[item.handler][dataItem.date].completedCaseCount.push(dataItem.completedCaseCount || 0);
                        }
                    });
                });

                // 拼接图例基础数据
                let seriesDataObj = {};
                handlerStackArr.forEach((item) => {
                    let seriesData = [];

                    seriesData.push({
                        name: '接案量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        stack: item,
                        data: []
                    });
                    // for (let i = 0; i < xAxisData.length; i++) {
                    //     if (chartType == 'case') {
                    //         seriesData.push({
                    //             name: i == 0 ? '未决' : '已决',
                    //             type: 'bar',
                    //             barMaxWidth: '28px',
                    //             stack: item,
                    //             data: []
                    //         });
                    //     } else {
                    //         seriesData.push({
                    //             name: i == 0 ? '预定损' : '已完成',
                    //             type: 'bar',
                    //             barMaxWidth: '28px',
                    //             stack: item,
                    //             data: []
                    //         });
                    //     }
                    // }
                    seriesDataObj[item] = { data: seriesData };
                });

                // 组合最终展示数据
                // monthSeriesObj = {人:{2023-09:[]}}
                for (const key in monthSeriesObj) {
                    let nameObj = monthSeriesObj[key];
                    // nameObj = {2023-09:[]}
                    for (const dateKey in nameObj) {
                        // xAxisData = [2023-09,2023-10]
                        xAxisData.forEach((dateItem, dateIndex) => {
                            if (dateItem == dateKey) {
                                seriesDataObj[key].data.forEach((keyItem) => {
                                    if (chartType == 'case') {
                                        nameObj[dateKey].connection.forEach((item, index) => {
                                            keyItem.data.push({ value: item, customAttr: [key, dateKey] });
                                        });
                                        // if (keyItem.name == '未决') {
                                        //     nameObj[dateKey].undecidedCaseCount.forEach((item, index) => {
                                        //         keyItem.data.push({ value: item, customAttr: [key, dateKey] });
                                        //     });
                                        // } else {
                                        //     // nameObj[dateKey].decidedCaseCount.forEach((item, index) => {
                                        //     //     keyItem.data.push({ value: item, customAttr: [key, dateKey] });
                                        //     // });
                                        //     nameObj[dateKey].connection.forEach((item, index) => {
                                        //         keyItem.data.push({ value: item, customAttr: [key, dateKey] });
                                        //     });
                                        // }
                                    } else {
                                        nameObj[dateKey].connection.forEach((item, index) => {
                                            keyItem.data.push({ value: item, customAttr: [key, dateKey] });
                                        });
                                        // if (keyItem.name == '预定损') {
                                        //     nameObj[dateKey].preLossCaseCount.forEach((item, index) => {
                                        //         keyItem.data.push({ value: item, customAttr: [key, dateKey] });
                                        //     });
                                        // } else {
                                        //     // nameObj[dateKey].completedCaseCount.forEach((item, index) => {
                                        //     //     keyItem.data.push({ value: item, customAttr: [key, dateKey] });
                                        //     // });
                                        //     nameObj[dateKey].connection.forEach((item, index) => {
                                        //         keyItem.data.push({ value: item, customAttr: [key, dateKey] });
                                        //     });
                                        // }
                                    }
                                });
                            }
                        });
                    }
                }

                for (const key in seriesDataObj) {
                    tmpSeries = tmpSeries.concat(seriesDataObj[key].data);
                }
            }
            return {
                xAxisData: xAxisData,
                chartType: chartType,
                weekSeriesObj: monthSeriesObj,
                tmpSeries: tmpSeries
            };
        },
        renderCaseBarChartOfWeek(data, title, chart, maxWeekNum) {
            const color = ['#8375C4', '#6AC0FD', '#FAB533', '#5CAF33', '#C194F8', '#9BECFB'];
            const xAxis = { type: 'category', data: data.xAxisData };
            const yAxis = {
                type: 'value'
            };

            let legends = [];
            const series = data.tmpSeries;

            if (maxWeekNum == 'null') {
                chart.clear();
                return;
            }

            for (let i = 0; i < maxWeekNum; i++) {
                legends.push(`第${i + 1}周`);
            }

            const option = {
                color: color,
                xAxis: [xAxis],
                yAxis: [yAxis],
                title: { ...DEFAULT_TITLE_STYLE, text: title },
                tooltip: {
                    ...DEFAULT_TOOLTIP_STYLE,
                    formatter: (params) => {
                        let tr = '';
                        let html = '';
                        let currentParamsData = data.weekSeriesObj[params.data.customAttr][params.name];
                        // <td width="100px" style="text-align:center;">周接案未决案件量</td><td style="text-align:center;">${currentParamsData.undecidedCaseCount[index]}</td>
                        if (data.chartType == 'case') {
                            if (currentParamsData.connection) {
                                currentParamsData.connection.forEach((item, index) => {
                                    tr += `<tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[index]}"></span></td><td>第${index + 1}周</td><td style="text-align:center;">${
                                        currentParamsData.connection[index]
                                    }</td><td style="text-align:center;">${currentParamsData.decidedCaseCount[index]}</td></tr>`;
                                });
                            }

                            html =
                                `
                            <div>
                                <span>${params.name}</span><span style="margin-left:22px">${params.data.customAttr}</span>
                                <hr>
                                 <table>
                                     <tr height="20px"><td width="20px"></td><td width="50px"></td><td width="55px" style="text-align:center;">周接案量</td><td width="140px" style="text-align:center;">周决案件量</td></tr>
                                     ` +
                                tr +
                                `
                                 </table>
                            </div>
                        `;
                        } else {
                            // <td width="20px" style="text-align:center;">周预定损</td><td style="text-align:center;">${currentParamsData.preLossCaseCount[index]}</td>
                            if (currentParamsData.connection) {
                                currentParamsData.connection.forEach((item, index) => {
                                    tr += `<tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[index]}"></span></td><td>第${index + 1}周</td><td style="text-align:center;">${
                                        currentParamsData.connection[index]
                                    }</td><td width="30px"></td><td style="text-align:center;">${currentParamsData.completedCaseCount[index]}</td></tr>`;
                                });
                            }

                            html =
                                `
                            <div>
                                <span>${params.name}</span><span style="margin-left:22px">${params.data.customAttr}</span>
                                <hr>
                                 <table>
                                     <tr height="20px"><td width="20px"></td><td width="50px"></td><td width="55px" style="text-align:center;">周接案量</td><td width="20px"></td><td width="100px" style="text-align:center;">周完成量</td></tr>
                                     ` +
                                tr +
                                `
                                 </table>
                            </div>
                        `;
                        }
                        return html;
                    }
                },
                grid: DEFAULT_GRID_SYTLE,
                legend: {
                    data: legends,
                    borderRadius: 20
                },
                series: series,
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: 30
                    }
                ]
            };
            chart.clear();
            chart.setOption(option);
        },
        renderCaseBarChartOfMonth(data, title, chart) {
            const color = ['#6AC0FD', '#FAB533', '#6AC0FD', '#5CAF33', '#C194F8', '#9BECFB'];
            const xAxis = { type: 'category', data: data.xAxisData };
            const yAxis = {
                type: 'value'
            };

            let legends = [];
            if (data.chartType == 'case') {
                legends = [];
            } else {
                legends = [];
            }
            const series = data.tmpSeries;

            const option = {
                color: color,
                xAxis: [xAxis],
                yAxis: [yAxis],
                title: { ...DEFAULT_TITLE_STYLE, text: title },
                tooltip: {
                    ...DEFAULT_TOOLTIP_STYLE,
                    formatter: (params) => {
                        let tr = '';
                        let html = '';
                        let currentParamsData = data.weekSeriesObj[params.data.customAttr[0]][params.data.customAttr[1]];

                        if (data.chartType == 'case') {
                            // <td width="100px" style="text-align:center;">本月接案未决案件量</td><td style="text-align:center;">${currentParamsData.undecidedCaseCount[index]}</td>
                            if (currentParamsData.connection) {
                                currentParamsData.connection.forEach((item, index) => {
                                    tr += `<tr height="20px"><td>${params.name}</td><td width="20px"></td><td style="text-align:center;">${currentParamsData.connection[index]}</td><td style="text-align:center;">${currentParamsData.decidedCaseCount[index]}</td></tr>`;
                                });
                            }

                            html =
                                `
                            <div>
                                <span>${params.data.customAttr[0]}</span>
                                <hr>
                                 <table>
                                     <tr height="20px"><td width="20px"></td><td width="10px"></td><td width="55px" style="text-align:center;">本月接案量</td><td width="120px" style="text-align:center;">本月决案量</td></tr>
                                     ` +
                                tr +
                                `
                                 </table>
                            </div>
                        `;
                        } else {
                            // <td width="20px" style="text-align:center;">本月预定损</td><td style="text-align:center;">${currentParamsData.preLossCaseCount[index]}</td>
                            if (currentParamsData.connection) {
                                currentParamsData.connection.forEach((item, index) => {
                                    tr += `<tr height="20px"><td>${params.name}</td><td style="text-align:center;">${currentParamsData.connection[index]}</td><td style="text-align:center;">${currentParamsData.completedCaseCount[index]}</td></tr>`;
                                });
                            }

                            html =
                                `
                            <div>
                                <span>${params.data.customAttr[0]}</span>
                                <hr>
                                 <table>
                                     <tr height="20px"><td width="20px"></td><td width="90px" style="text-align:center;">本月接案量</td><td width="100px" style="text-align:center;">本月完成量</td></tr>
                                     ` +
                                tr +
                                `
                                 </table>
                            </div>
                        `;
                        }
                        return html;
                    }
                },
                grid: DEFAULT_GRID_SYTLE,
                legend: {
                    data: legends,
                    borderRadius: 20
                },
                series: series,
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: 30
                    }
                ]
            };
            chart.clear();
            chart.setOption(option);
        },
        setPreCaseDailyMetricsCharts(response) {
            let chartDom = document.getElementById('preCaseDailyMetricsCharts');
            if (!chartDom) {
                return;
            }
            this.preCaseDailyMetricsCharts = echarts.init(chartDom);
            this.preCaseDailyMetricsCharts.clear();
            let xData = [];
            let series = [];
            let color = ['#2C7BE4', '#5DAF33', '#EF9A20'];

            let handlerDataObj = {};
            this.preHandlerValues.forEach((item) => {
                handlerDataObj[item] = {};
                let assignmentCaseCount = [];
                let completedCaseCount = [];
                let result = response.data.list.find((obj) => obj.handler.includes(item));
                result.data.forEach((item2) => {
                    xData.push(item2.date);
                    assignmentCaseCount.push(item2.assignmentCaseCount);
                    completedCaseCount.push(item2.completedCaseCount);

                    // {李小康：{2023-09-19:[接案量，已决，未决]}}
                    handlerDataObj[item][item2.date] = [];
                    handlerDataObj[item][item2.date].push(item2.assignmentCaseCount);
                    handlerDataObj[item][item2.date].push(item2.completedCaseCount);
                });
                xData = [...new Set(xData)];
                series.push({
                    name: item,
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: assignmentCaseCount
                });
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -10
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                },
                legend: {},
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: (params, a, b, c) => {
                        let tr = '';
                        let html = '';
                        let currentHandlerData = null;
                        for (let i = 0; i < params.length; i++) {
                            currentHandlerData = handlerDataObj[params[i].seriesName][params[i].axisValue];
                            tr += `<tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[i]}"></span></td><td>${params[i].seriesName}</td><td width="20px"></td><td style="text-align:center;">${currentHandlerData[0]}</td><td style="text-align:center;">${currentHandlerData[1]}</td></tr>`;
                        }

                        html =
                            `
                            <div>
                                <span>${params[0].axisValue}</span>
                                <hr>
                                 <table>
                                     <tr height="20px"><td width="10px"></td><td width="40px" style="text-align:center;">作业人</td><td width="20px"></td><td width="60px" style="text-align:center;">今日接案量</td><td width="120px" style="text-align:center;">今日完成量</td></tr>
                                     ` +
                            tr +
                            `
                                 </table>
                            </div>
                        `;
                        return html;
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData
                },
                yAxis: [
                    {
                        name: '接案量',
                        type: 'value'
                    }
                ],
                series: series,
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: 20
                    }
                ]
            };
            this.preCaseDailyMetricsCharts.setOption(option);
        },
        setCaseWeekCharts1(response) {
            let chartDom = document.getElementById('caseWeekCharts1');
            if (!chartDom) {
                return;
            }
            this.caseWeekCharts1 = echarts.init(chartDom);
            this.caseWeekCharts1.clear();
            let xData = [];
            let series = [];

            let option = {
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar'
                    }
                ]
            };
            this.caseWeekCharts1.setOption(option);
        },

        queryData() {
            this.tabClickQuery();
            // 公共图表
            this.getOfficialCaseClosingTimeMetricsCharts();
            this.getPreCaseClosingTimeMetricsCharts();
            this.getOfficialCaseLossAmountMetricsCharts();
            this.getOfficialCaseAvgLossAmountMetricsCharts();
        },

        downloadXlsx(type) {
            let instanceUrl = '';
            let instanceForm = this.navForm;
            this.fileFLag = false;
            switch (type) {
                case 0:
                    // 正式报案作业量统计-总
                    instanceUrl = '/tpa/api/dashboard/taskForce/officialCaseMetrics/excel';
                    break;
                case 1:
                    // 正式报案作业量统计-日
                    instanceUrl = '/tpa/api/dashboard/taskForce/officialCaseMetricsDailyWeeklyMonthly/excel';
                    instanceForm.queryType = 'daily';
                    instanceForm.handlerList = this.handlerValues;
                    break;
                case 2:
                    // 预报案作业量统计-日
                    instanceUrl = '/tpa/api/dashboard/taskForce/preCaseMetricsDailyWeeklyMonthly/excel';
                    instanceForm.queryType = 'daily';
                    instanceForm.handlerList = this.preHandlerValues;
                    break;
                case 3:
                    // 正式报案作业量统计-周
                    instanceUrl = '/tpa/api/dashboard/taskForce/officialCaseMetricsDailyWeeklyMonthly/excel';
                    instanceForm.queryType = 'weekly';
                    instanceForm.handlerList = this.caseWeekHandlerValues;
                    break;
                case 4:
                    // 预报案作业量统计-周
                    instanceUrl = '/tpa/api/dashboard/taskForce/preCaseMetricsDailyWeeklyMonthly/excel';
                    instanceForm.queryType = 'weekly';
                    instanceForm.handlerList = this.preCaseWeekHandlerValues;
                    break;
                case 5:
                    // 正式报案作业量统计-月
                    instanceUrl = '/tpa/api/dashboard/taskForce/officialCaseMetricsDailyWeeklyMonthly/excel';
                    instanceForm.queryType = 'monthly';
                    instanceForm.handlerList = this.caseMonthHandlerValues;
                    break;
                case 6:
                    // 预报案报案作业量统计-月
                    instanceUrl = '/tpa/api/dashboard/taskForce/preCaseMetricsDailyWeeklyMonthly/excel';
                    instanceForm.queryType = 'monthly';
                    instanceForm.handlerList = this.preCaseMonthHandlerValues;
                    break;
                case 9901:
                    // 作业人员结案时效统计
                    instanceUrl = '/tpa/api/dashboard/taskForce/officialCaseClosingTimeMetrics/excel';
                    break;
                case 9902:
                    // 作业人员减损金额
                    instanceUrl = '/tpa/api/dashboard/taskForce/officialCaseLossAmountMetrics/excel';
                    break;
                case 9903:
                    // 作业人员结案时效统计 - 结案时间
                    instanceUrl = '/tpa/api/dashboard/taskForce/officialCaseClosingTimeMetricsByDecidedTime/excel';
                    break;
                case 9904:
                    // 作业人员减损金额 - 结案时间
                    instanceUrl = '/tpa/api/dashboard/taskForce/officialCaseLossAmountMetricsByDecidedTime/excel';
                    break;

                default:
                    break;
            }
            this['download' + type] = true;
            this.instance.post(instanceUrl, instanceForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.downloadFile(data.data, 'download' + type);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        downloadFile(fileKey, btnLoading) {
            if (this.tmpOSSClient) {
                this.isExistObject(fileKey);
                if (this.fileTime > 200) {
                    this.$message.error('文件下载失败，请稍后重试！');
                    return;
                }

                if (!this.fileFLag) {
                    setTimeout(() => {
                        this.fileTime++;
                        this.downloadFile(fileKey, btnLoading);
                    }, 3000);
                } else {
                    this.fileFLag = false;
                    this.fileTime = 0;
                    let fileUrl = this.tmpOSSClient.signatureUrl(fileKey);
                    this[btnLoading] = false;
                    location.href = fileUrl;
                }
            }
        },
        async isExistObject(name, options = {}) {
            try {
                await this.tmpOSSClient.head(name, options);
                this.fileFLag = true;
            } catch (error) {
                if (error.code === 'NoSuchKey') {
                    this.fileFLag = false;
                }
            }
        },
        getOfficialCaseClosingTimeMetricsByDecidedTimeCharts() {
            this.loadingChart14 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/officialCaseClosingTimeMetricsByDecidedTime', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.officialCaseClosingTimeMetricsByDecidedTimeTitle = data.data.title;
                    this.setOfficialCaseClosingTimeMetricsByDecidedTime(data.data);
                    this.loadingChart14 = false;
                }
            });
        },
        setOfficialCaseClosingTimeMetricsByDecidedTime(response) {
            let chartDom = document.getElementById('officialCaseClosingTimeMetricsByDecidedTime');
            if (!chartDom) {
                return;
            }
            this.officialCaseClosingTimeMetricsByDecidedTime = echarts.init(chartDom);
            let xData = [];

            let avgClosingTime = [];
            let closingCaseCount = [];
            let closingRate = [];
            let color = ['#2C7BE4', '#5DAF33', '#EF9A20'];

            response.data.list.forEach((item) => {
                xData.push(item.handler);
                avgClosingTime.push(item.avgClosingTime);
                closingCaseCount.push(item.closingCaseCount);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -15
                    }
                },
                grid: {
                    left: '3%',
                    top: '60px',
                    right: '4%',
                    bottom: '0px',
                    containLabel: true
                },
                legend: {
                    top: '15px'
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisLabel: {
                        show: true,
                        margin: 10,
                        rotate: 20
                    }
                },
                yAxis: [
                    {
                        name: '结案量',
                        type: 'value'
                    },
                    {
                        name: '案均时效(H)',
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '结案量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: closingCaseCount
                    },
                    {
                        name: '案均结案时效(H)',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + 'H';
                            }
                        },
                        data: avgClosingTime
                    }
                ]
            };
            this.officialCaseClosingTimeMetricsByDecidedTime.setOption(option);
        },

        getPreCaseClosingTimeMetricsByDecidedTimeCharts() {
            this.loadingChart15 = true;
            console.log(this.navForm);
            this.instance.post('/tpa/api/dashboard/taskForce/preCaseClosingTimeMetricsByDecidedTime', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.preCaseClosingTimeMetricsByDecidedTimeTitle = data.data.title;
                    this.setPreCaseClosingTimeMetricsByDecidedTimeCharts(data.data);
                    this.loadingChart15 = false;
                }
            });
        },

        setPreCaseClosingTimeMetricsByDecidedTimeCharts(response) {
            let chartDom = document.getElementById('preCaseClosingTimeMetricsByDecidedTime');
            if (!chartDom) {
                return;
            }
            this.preCaseClosingTimeMetricsByDecidedTime = echarts.init(chartDom);
            let xData = [];

            let avgClosingTime = [];
            let completedCaseCount = [];
            let color = ['#2C7BE4', '#5DAF33', '#EF9A20'];

            response.data.list.forEach((item) => {
                xData.push(item.handler);
                avgClosingTime.push(item.avgClosingTime);
                completedCaseCount.push(item.completedCaseCount);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -15
                    }
                },
                grid: {
                    left: '3%',
                    top: '60px',
                    right: '4%',
                    bottom: '0px',
                    containLabel: true
                },
                legend: {
                    top: '15px'
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisLabel: {
                        show: true,
                        margin: 10,
                        rotate: 20
                    }
                },
                yAxis: [
                    {
                        name: '完成量',
                        type: 'value'
                    },
                    {
                        name: '完成时效(H)',
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '完成量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: completedCaseCount
                    },
                    {
                        name: '案均完成时效(H)',
                        type: 'line',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + 'H';
                            }
                        },
                        data: avgClosingTime
                    }
                ]
            };
            this.preCaseClosingTimeMetricsByDecidedTime.setOption(option);
        },
        getOfficialCaseLossAmountMetricsByDecidedTimeCharts() {
            this.loadingChart16 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/officialCaseLossAmountMetricsByDecidedTime', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.officialCaseLossAmountMetricsByDecidedTimeTitle = data.data.title;
                    this.setOfficialCaseLossAmountMetricsByDecidedTimeCharts(data.data);
                    this.loadingChart16 = false;
                }
            });
        },

        setOfficialCaseLossAmountMetricsByDecidedTimeCharts(response) {
            let chartDom = document.getElementById('officialCaseLossAmountMetricsByDecidedTime');
            if (!chartDom) {
                return;
            }
            this.officialCaseLossAmountMetricsByDecidedTime = echarts.init(chartDom);
            let xData = [];

            let lossAmount = [];
            let lossRate = [];
            let color = ['#2C7BE4', '#EF9A20'];

            response.data.list.forEach((item) => {
                xData.push(item.handler);
                lossAmount.push(item.lossAmount.replace(/,/g, ''));
                lossRate.push(item.lossRate);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -15
                    }
                },
                grid: {
                    left: '3%',
                    top: '60px',
                    right: '4%',
                    bottom: '0px',
                    containLabel: true
                },
                legend: {
                    top: '15px'
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisLabel: {
                        show: true,
                        margin: 10,
                        rotate: 20
                    }
                },
                yAxis: [
                    {
                        name: '减损金额(元)',
                        type: 'value'
                    },
                    {
                        name: '减损率(%)',
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '减损金额(元)',
                        type: 'bar',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: lossAmount
                    },
                    {
                        name: '减损率(%)',
                        type: 'line',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '%';
                            }
                        },
                        data: lossRate
                    }
                ]
            };
            this.officialCaseLossAmountMetricsByDecidedTime.setOption(option);
        },

        getOfficialCaseAvgLossAmountMetricsByDecidedTimeCharts() {
            this.loadingChart17 = true;
            this.instance.post('/tpa/api/dashboard/taskForce/officialCaseAvgLossAmountMetricsByDecidedTime', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.officialCaseAvgLossAmountMetricsByDecidedTimeTitle = data.data.title;
                    this.setOfficialCaseAvgLossAmountMetricsByDecidedTimeCharts(data.data);
                    this.loadingChart17 = false;
                }
            });
        },

        setOfficialCaseAvgLossAmountMetricsByDecidedTimeCharts(response) {
            let chartDom = document.getElementById('officialCaseAvgLossAmountMetricsByDecidedTime');
            if (!chartDom) {
                return;
            }
            this.officialCaseAvgLossAmountMetricsByDecidedTime = echarts.init(chartDom);
            let xData = [];

            let avgLossAmount = [];
            let avgLossRate = [];
            let color = ['#2C7BE4', '#EF9A20'];

            response.data.list.forEach((item) => {
                xData.push(item.handler);
                avgLossAmount.push(item.avgLossAmount.replace(/,/g, ''));
                avgLossRate.push(item.avgLossRate);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -15
                    }
                },
                grid: {
                    left: '3%',
                    top: '60px',
                    right: '4%',
                    bottom: '0px',
                    containLabel: true
                },
                legend: {
                    top: '15px'
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisLabel: {
                        show: true,
                        margin: 10,
                        rotate: 20
                    }
                },
                yAxis: [
                    {
                        name: '案均减损金额(元)',
                        type: 'value'
                    },
                    {
                        name: '案均减损率(%)',
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '案均减损金额(元)',
                        type: 'bar',
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: avgLossAmount
                    },
                    {
                        name: '减损率(%)',
                        type: 'line',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '%';
                            }
                        },
                        data: avgLossRate
                    }
                ]
            };
            this.officialCaseAvgLossAmountMetricsByDecidedTime.setOption(option);
        }
    },

    updated() {}
};
