
import OSS from 'ali-oss';
import * as echarts from 'echarts';
import fa from 'element-ui/src/locale/lang/fa';

const DEFAULT_TOOLTIP_STYLE = {
    trigger: 'item',
    axisPointer: {
        type: 'cross',
        label: {
            backgroundColor: '#6a7985'
        }
    }
};

export default {
    data() {
        return {
            resetOssNum: 0,
            navForm: {
                startTime: '',
                endTime: ''
            },
            pickerOptions1: {},
            pickerOptions2: {},
            activeName: 'case',
            caseOverviewMetrics: {
                date: '',
                assignmentCaseCount: 0,
                decidedCaseCount: 0
            },
            preCaseOverviewMetrics: {
                date: '',
                assignmentCaseCount: 0,
                decidedCaseCount: 0
            },
            loadingDailyChart: false,
            loadingWeeklyChart: false,
            loadingPreDailyChart: false,
            loadingPreWeeklyChart: false,
            download1: false,
            download2: false,
            download3: false,
            download4: false,
            download5: false,
            download6: false
        };
    },
    mounted() {
        this.initOSSClient();
        let today = new Date();
        today.setDate(today.getDate() - 1);
        this.navForm.startTime = '2023-09-01';
        this.navForm.endTime = this.formatDate(today);

        this.pickerOptions1.disabledDate = (time) => {
            // 昨天
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return time.getTime() > yesterday || time.getTime() < this.getTimeStamp('2023-06-01');
        };
        this.pickerOptions2.disabledDate = (time) => {
            // 昨天
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return time.getTime() > yesterday || time.getTime() < this.getTimeStamp('2023-06-01');
        };

        this.queryData();
    },
    methods: {
        startTimeChange() {
            this.pickerOptions2.disabledDate = (time) => {
                // 昨天
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return time.getTime() > yesterday || time.getTime() < this.getTimeStamp(this.navForm.startTime);
            };
        },
        endTimeChange() {
            this.pickerOptions1.disabledDate = (time) => {
                // 昨天
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return time.getTime() > this.getTimeStamp(this.navForm.endTime) || time.getTime() < this.getTimeStamp('2023-06-01');
            };
        },

        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getTimeStamp(timeStr) {
            return new Date(timeStr.split('-')[0], timeStr.split('-')[1] - 1, timeStr.split('-')[2]).getTime();
        },
        handleClick(tab, event) {
            this.$nextTick(() => {
                this.tabClickQuery();
            });
        },
        tabClickQuery() {
            switch (this.activeName) {
                case 'case':
                    this.getCaseOverviewMetrics();
                    this.getCaseOverviewMetricsDailyCharts();
                    this.getCaseOverviewMetricsWeeklyCharts();
                    break;
                case 'pre-case':
                    this.getPreCaseOverviewMetrics();
                    this.getPreCaseOverviewMetricsDailyCharts();
                    this.getPreCaseOverviewMetricsWeeklyCharts();
                    break;
                default:
                    break;
            }
        },

        queryData() {
            this.getCaseOverviewMetrics();
            this.getCaseOverviewMetricsDailyCharts();
            this.getCaseOverviewMetricsWeeklyCharts();
        },

        getCaseOverviewMetrics() {
            this.instance.post('/tpa/api/dashboard/caseOverview/metrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.caseOverviewMetrics = data.data;
                }
            });
        },

        getCaseOverviewMetricsDailyCharts() {
            this.loadingDailyChart = true;
            this.instance.post('/tpa/api/dashboard/caseOverview/metrics/daily', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.setCaseOverviewMetricsDailyCharts(data.data);
                    this.loadingDailyChart = false;
                }
            });
        },

        setCaseOverviewMetricsDailyCharts(response) {
            let chartDom = document.getElementById('caseOverviewMetricsDailyCharts');
            if (!chartDom) {
                return;
            }
            this.caseOverviewMetricsDailyCharts = echarts.init(chartDom);
            this.caseOverviewMetricsDailyCharts.clear();
            let xData = [];
            let color = ['#2C7BE4', '#5DAF33', '#EF9A20'];
            let assignmentCaseCount = [];
            let decidedCaseCount = [];

            response.data.list.forEach((item) => {
                xData.push(item.date);
                assignmentCaseCount.push(item.assignmentCaseCount);
                decidedCaseCount.push(item.decidedCaseCount);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -10
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                },
                legend: {},
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData
                },
                yAxis: [
                    {
                        name: '接案量',
                        type: 'value'
                    },
                    {
                        name: '累计已接案件当日结案量',
                        type: 'value',
                        yAxisIndex: 1,
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '接案量',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            // valueFormatter: function (value) {
                            //     return '接案量:' + value;
                            // }
                        },
                        data: assignmentCaseCount
                    },
                    {
                        name: '累计已接案件当日结案量',
                        type: 'line',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            // valueFormatter: function (value) {
                            //     return '累计已接案件当日结案量:' + value;
                            // }
                        },
                        data: decidedCaseCount
                    }
                ],
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: 20
                    }
                ]
            };
            this.caseOverviewMetricsDailyCharts.setOption(option);
        },

        getCaseOverviewMetricsWeeklyCharts() {
            this.loadingWeeklyChart = true;
            this.instance.post('/tpa/api/dashboard/caseOverview/metrics/monthly', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.setCaseOverviewMetricsWeeklyCharts(data.data);
                    this.setCaseOverviewMetricsMonthlyCharts(data.data);
                    this.loadingWeeklyChart = false;
                }
            });
        },

        setCaseOverviewMetricsWeeklyCharts(response) {
            let chartDom = document.getElementById('caseOverviewMetricsWeeklyCharts');
            if (!chartDom) {
                return;
            }
            this.caseOverviewMetricsWeeklyCharts = echarts.init(chartDom);
            this.caseOverviewMetricsWeeklyCharts.clear();
            let xData = [];
            let color = ['#8375C4', '#6AC0FD', '#FAB533', '#5CAF33', '#C194F8', '#9BECFB'];

            let xSeries = [
                {
                    week: '第1周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                },
                {
                    week: '第2周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                },
                {
                    week: '第3周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                },
                {
                    week: '第4周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                },
                {
                    week: '第5周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                },
                {
                    week: '第6周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                }
            ];

            let weeklyChartData = [];
            response.data.list.forEach((item) => {
                xData.push(item.date);
                xSeries.forEach((item1) => {
                    let data = item.weekData.find((item2) => item2.date === item1.week);
                    if (data === undefined) {
                        item1.assignmentCaseCount.push(0);
                        item1.decidedCaseCount.push(0);
                    } else {
                        item1.assignmentCaseCount.push(data.assignmentCaseCount);
                        item1.decidedCaseCount.push(data.decidedCaseCount);
                    }
                });
                item.weekData.forEach((item1) => {
                    let weekdata = {
                        month: item.date,
                        week: item1.date,
                        assignmentCaseCount: item1.assignmentCaseCount,
                        decidedCaseCount: item1.decidedCaseCount
                    };
                    weeklyChartData.push(weekdata);
                });
            });

            let assignmentCaseCount = [];
            let decidedCaseCount = [];
            xSeries.forEach((item) => {
                assignmentCaseCount.push(item.assignmentCaseCount);
                decidedCaseCount.push(item.decidedCaseCount);
            });

            let series = ['第1周', '第2周', '第3周', '第4周', '第5周', '第6周'].map((name, sid) => {
                return [
                    {
                        name,
                        type: 'bar',
                        stack: 'total',
                        barWidth: '30%',
                        emphasis: {
                            focus: 'series'
                        },
                        // tooltip: {
                        //     valueFormatter: function (value) {
                        //         return '接案量:' + value;
                        //     }
                        // },
                        label: {
                            show: false
                            // formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
                        },
                        data: assignmentCaseCount[sid]
                    },
                    {
                        name,
                        type: 'bar',
                        stack: 'Ad',
                        barWidth: '30%',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        // tooltip: {
                        // valueFormatter: function (value) {
                        //     return '结案量:' + value;
                        // }
                        // },
                        label: {
                            show: false
                            // formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
                        },
                        data: decidedCaseCount[sid]
                    }
                ];
            });
            let seriesNew = [];

            series.forEach((item) => {
                item.forEach((series) => {
                    seriesNew.push(series);
                });
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -10
                    }
                },
                tooltip: {
                    ...DEFAULT_TOOLTIP_STYLE,
                    formatter: (params) => {
                        let tr = '';
                        let html = '';
                        let monthly = params.name;
                        let weeklyData = weeklyChartData.filter((item) => item.month === monthly);
                        weeklyData.forEach((item, index) => {
                            tr += `<tr height="20px">
                                        <td width="20px">
                                            <span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[index]}"></span>
                                        </td>
                                        <td>${item.week}</td>
                                        <td style="text-align:center;">${item.assignmentCaseCount}</td>
                                        <td width="20px"></td>
                                        <td style="text-align:center;">${item.decidedCaseCount}</td>
                                   </tr>`;
                        });
                        html =
                            `
                            <div>
                                <span>${params.name}</span>
                                <hr>
                                 <table>
                                     <tr height="20px">
                                        <td width="20px"></td>
                                        <td width="50px"></td>
                                        <td width="55px" style="text-align:center;">接案量</td>
                                        <td width="20px"></td>
                                        <td width="20px" style="text-align:center;">累计已接案件当周结案量</td>
                                     </tr>
                                     ` +
                            tr +
                            `
                                 </table>
                            </div>
                        `;
                        return html;
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                },
                legend: {},
                color: color,
                xAxis: {
                    type: 'category',
                    data: xData
                },
                yAxis: [
                    {
                        name: '接案量',
                        type: 'value'
                    },
                    {
                        name: '累计已接案件当周结案量',
                        type: 'value',
                        yAxisIndex: 1,
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: seriesNew,
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: 20
                    }
                ]
            };
            this.caseOverviewMetricsWeeklyCharts.setOption(option);
        },

        setCaseOverviewMetricsMonthlyCharts(response) {
            let chartDom = document.getElementById('caseOverviewMetricsMonthlyCharts');
            if (!chartDom) {
                return;
            }
            this.caseOverviewMetricsMonthlyCharts = echarts.init(chartDom);
            this.caseOverviewMetricsMonthlyCharts.clear();
            let xData = [];
            let color = ['#2C7BE4', '#5DAF33', '#EF9A20'];
            let assignmentCaseCount = [];
            let decidedCaseCount = [];

            response.data.list.forEach((item) => {
                xData.push(item.date);
                assignmentCaseCount.push(item.assignmentCaseCount);
                decidedCaseCount.push(item.decidedCaseCount);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -10
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                },
                legend: {},
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData
                },
                yAxis: [
                    {
                        name: '接案量',
                        type: 'value'
                    },
                    {
                        name: '累计已接案件当月结案量',
                        type: 'value',
                        yAxisIndex: 1,
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '接案量',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            // valueFormatter: function (value) {
                            //     return '接案量:' + value;
                            // }
                        },
                        data: assignmentCaseCount
                    },
                    {
                        name: '累计已接案件当月结案量',
                        type: 'bar',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            // valueFormatter: function (value) {
                            //     return '累计已接案件当日结案量:' + value;
                            // }
                        },
                        data: decidedCaseCount
                    }
                ],
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: 20
                    }
                ]
            };
            this.caseOverviewMetricsMonthlyCharts.setOption(option);
        },

        getPreCaseOverviewMetrics() {
            this.instance.post('/tpa/api/dashboard/caseOverview/preCaseMetrics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.preCaseOverviewMetrics = data.data;
                }
            });
        },
        getPreCaseOverviewMetricsDailyCharts() {
            this.loadingPreDailyChart = true;
            this.instance.post('/tpa/api/dashboard/caseOverview/preCaseMetrics/daily', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.setPreCaseOverviewMetricsDailyCharts(data.data);
                    this.loadingPreDailyChart = false;
                }
            });
        },

        setPreCaseOverviewMetricsDailyCharts(response) {
            let chartDom = document.getElementById('preCaseOverviewMetricsDailyCharts');
            if (!chartDom) {
                return;
            }
            this.preCaseOverviewMetricsDailyCharts = echarts.init(chartDom);
            this.preCaseOverviewMetricsDailyCharts.clear();
            let xData = [];
            let color = ['#2C7BE4', '#5DAF33', '#EF9A20'];
            let assignmentCaseCount = [];
            let decidedCaseCount = [];

            response.data.list.forEach((item) => {
                xData.push(item.date);
                assignmentCaseCount.push(item.assignmentCaseCount);
                decidedCaseCount.push(item.decidedCaseCount);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -10
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                },
                legend: {},
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData
                },
                yAxis: [
                    {
                        name: '接案量',
                        type: 'value'
                    },
                    {
                        name: '累计已接案件当日完成量',
                        type: 'value',
                        yAxisIndex: 1,
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '接案量',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            // valueFormatter: function (value) {
                            //     return '接案量:' + value;
                            // }
                        },
                        data: assignmentCaseCount
                    },
                    {
                        name: '累计已接案件当日完成量',
                        type: 'line',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            // valueFormatter: function (value) {
                            //     return '累计已接案件当日完成量:' + value;
                            // }
                        },
                        data: decidedCaseCount
                    }
                ],
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: 20
                    }
                ]
            };
            this.preCaseOverviewMetricsDailyCharts.setOption(option);
        },

        getPreCaseOverviewMetricsWeeklyCharts() {
            this.loadingWeeklyChart = true;
            this.instance.post('/tpa/api/dashboard/caseOverview/preCaseMetrics/monthly', this.navForm).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.setPreCaseOverviewMetricsWeeklyCharts(data.data);
                    this.setPreCaseOverviewMetricsMonthlyCharts(data.data);
                    this.loadingWeeklyChart = false;
                }
            });
        },

        setPreCaseOverviewMetricsWeeklyCharts(response) {
            let chartDom = document.getElementById('preCaseOverviewMetricsWeeklyCharts');
            if (!chartDom) {
                return;
            }
            this.preCaseOverviewMetricsWeeklyCharts = echarts.init(chartDom);
            this.preCaseOverviewMetricsWeeklyCharts.clear();
            let xData = [];
            let color = ['#8375C4', '#6AC0FD', '#FAB533', '#5CAF33', '#C194F8', '#9BECFB'];

            let xSeries = [
                {
                    week: '第1周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                },
                {
                    week: '第2周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                },
                {
                    week: '第3周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                },
                {
                    week: '第4周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                },
                {
                    week: '第5周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                },
                {
                    week: '第6周',
                    assignmentCaseCount: [],
                    decidedCaseCount: []
                }
            ];

            let weeklyChartData = [];
            response.data.list.forEach((item) => {
                xData.push(item.date);
                xSeries.forEach((item1) => {
                    let data = item.weekData.find((item2) => item2.date === item1.week);
                    if (data === undefined) {
                        item1.assignmentCaseCount.push(0);
                        item1.decidedCaseCount.push(0);
                    } else {
                        item1.assignmentCaseCount.push(data.assignmentCaseCount);
                        item1.decidedCaseCount.push(data.decidedCaseCount);
                    }
                });
                item.weekData.forEach((item1) => {
                    let weekdata = {
                        month: item.date,
                        week: item1.date,
                        assignmentCaseCount: item1.assignmentCaseCount,
                        decidedCaseCount: item1.decidedCaseCount
                    };
                    weeklyChartData.push(weekdata);
                });
            });

            let assignmentCaseCount = [];
            let decidedCaseCount = [];
            xSeries.forEach((item) => {
                assignmentCaseCount.push(item.assignmentCaseCount);
                decidedCaseCount.push(item.decidedCaseCount);
            });

            let series = ['第1周', '第2周', '第3周', '第4周', '第5周', '第6周'].map((name, sid) => {
                return [
                    {
                        name,
                        type: 'bar',
                        stack: 'total',
                        barWidth: '30%',
                        emphasis: {
                            focus: 'series'
                        },
                        // tooltip: {
                        //     valueFormatter: function (value) {
                        //         return '接案量:' + value;
                        //     }
                        // },
                        label: {
                            show: false
                            // formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
                        },
                        data: assignmentCaseCount[sid]
                    },
                    {
                        name,
                        type: 'bar',
                        stack: 'Ad',
                        barWidth: '30%',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        // tooltip: {
                        // valueFormatter: function (value) {
                        //     return '结案量:' + value;
                        // }
                        // },
                        label: {
                            show: false
                            // formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
                        },
                        data: decidedCaseCount[sid]
                    }
                ];
            });
            let seriesNew = [];

            series.forEach((item) => {
                item.forEach((series) => {
                    seriesNew.push(series);
                });
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -10
                    }
                },
                tooltip: {
                    ...DEFAULT_TOOLTIP_STYLE,
                    formatter: (params) => {
                        let tr = '';
                        let html = '';
                        let monthly = params.name;
                        let weeklyData = weeklyChartData.filter((item) => item.month === monthly);
                        weeklyData.forEach((item, index) => {
                            tr += `<tr height="20px">
                                        <td width="20px">
                                            <span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[index]}"></span>
                                        </td>
                                        <td>${item.week}</td>
                                        <td style="text-align:center;">${item.assignmentCaseCount}</td>
                                        <td width="20px"></td>
                                        <td style="text-align:center;">${item.decidedCaseCount}</td>
                                   </tr>`;
                        });
                        html =
                            `
                            <div>
                                <span>${params.name}</span>
                                <hr>
                                 <table>
                                     <tr height="20px">
                                        <td width="20px"></td>
                                        <td width="50px"></td>
                                        <td width="55px" style="text-align:center;">接案量</td>
                                        <td width="20px"></td>
                                        <td width="20px" style="text-align:center;">累计已接案件当周完成量</td>
                                     </tr>
                                     ` +
                            tr +
                            `
                                 </table>
                            </div>
                        `;
                        return html;
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                },
                legend: {},
                color: color,
                xAxis: {
                    type: 'category',
                    data: xData
                },
                yAxis: [
                    {
                        name: '接案量',
                        type: 'value'
                    },
                    {
                        name: '累计已接案件当周完成量',
                        type: 'value',
                        yAxisIndex: 1,
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: seriesNew,
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: 20
                    }
                ]
            };
            this.preCaseOverviewMetricsWeeklyCharts.setOption(option);
        },

        setPreCaseOverviewMetricsMonthlyCharts(response) {
            let chartDom = document.getElementById('preCaseOverviewMetricsMonthlyCharts');
            if (!chartDom) {
                return;
            }
            this.preCaseOverviewMetricsMonthlyCharts = echarts.init(chartDom);
            this.preCaseOverviewMetricsMonthlyCharts.clear();
            let xData = [];
            let color = ['#2C7BE4', '#5DAF33', '#EF9A20'];
            let assignmentCaseCount = [];
            let decidedCaseCount = [];

            response.data.list.forEach((item) => {
                xData.push(item.date);
                assignmentCaseCount.push(item.assignmentCaseCount);
                decidedCaseCount.push(item.decidedCaseCount);
            });

            let option = {
                title: {
                    subtext: response.subTitle,
                    subtextStyle: {
                        fontSize: 11,
                        color: '#409EFF',
                        verticalAlign: 'top',
                        lineHeight: -10
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                },
                legend: {},
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xData
                },
                yAxis: [
                    {
                        name: '接案量',
                        type: 'value'
                    },
                    {
                        name: '累计已接案件当月结案量',
                        type: 'value',
                        yAxisIndex: 1,
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                series: [
                    {
                        name: '接案量',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            // valueFormatter: function (value) {
                            //     return '接案量:' + value;
                            // }
                        },
                        data: assignmentCaseCount
                    },
                    {
                        name: '累计已接案件当月结案量',
                        type: 'bar',
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        tooltip: {
                            // valueFormatter: function (value) {
                            //     return '累计已接案件当日结案量:' + value;
                            // }
                        },
                        data: decidedCaseCount
                    }
                ],
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: 20
                    }
                ]
            };
            this.preCaseOverviewMetricsMonthlyCharts.setOption(option);
        },

        downloadXlsx(type) {
            let instanceUrl = '';
            let instanceForm = this.navForm;
            this.fileFLag = false;
            switch (type) {
                case 1:
                    instanceUrl = '/tpa/api/dashboard/caseOverview/metrics/daily/excel';
                    break;
                case 2:
                    instanceUrl = '/tpa/api/dashboard/caseOverview/metrics/weekly/excel';
                    break;
                case 3:
                    instanceUrl = '/tpa/api/dashboard/caseOverview/metrics/monthly/excel';
                    break;
                case 4:
                    // 正式报案作业量统计-周
                    instanceUrl = '/tpa/api/dashboard/caseOverview/preCaseMetrics/daily/excel';
                    break;
                case 5:
                    // 预报案作业量统计-周
                    instanceUrl = '/tpa/api/dashboard/caseOverview/preCaseMetrics/weekly/excel';
                    break;
                case 6:
                    // 正式报案作业量统计-月
                    instanceUrl = '/tpa/api/dashboard/caseOverview/preCaseMetrics/monthly/excel';
                    break;
                default:
                    break;
            }
            this['download' + type] = true;
            this.instance.post(instanceUrl, instanceForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.downloadFile(data.data, 'download' + type);
                } else {
                    this.$message.error(data.message);
                }
            });
        },

        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        downloadFile(fileKey, btnLoading) {
            if (this.tmpOSSClient) {
                this.isExistObject(fileKey);
                if (this.fileTime > 200) {
                    this.$message.error('文件下载失败，请稍后重试！');
                    return;
                }

                if (!this.fileFLag) {
                    setTimeout(() => {
                        this.fileTime++;
                        this.downloadFile(fileKey, btnLoading);
                    }, 3000);
                } else {
                    this.fileFLag = false;
                    this.fileTime = 0;
                    let fileUrl = this.tmpOSSClient.signatureUrl(fileKey);
                    this[btnLoading] = false;
                    location.href = fileUrl;
                }
            }
        },
        async isExistObject(name, options = {}) {
            try {
                await this.tmpOSSClient.head(name, options);
                this.fileFLag = true;
            } catch (error) {
                if (error.code === 'NoSuchKey') {
                    this.fileFLag = false;
                }
            }
        }
    }
};
