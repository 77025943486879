
import OSS from 'ali-oss';
import * as echarts from 'echarts';
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
const getTimeStamp = (timeStr) => {
    return new Date(timeStr.split('-')[0], timeStr.split('-')[1] - 1, timeStr.split('-')[2]).getTime();
};
const DEFAULT_GRID_SYTLE = {
    left: '80px',
    right: '80px'
};
export default {
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            resetOssNum: 0,
            pickerOptions1: {},
            pickerOptions2: {},
            navForm: {
                startTime: '',
                endTime: ''
            },
            download1: false,
            download2: false,
            download3: false,
            claimIntervalAmountChartBoxLoading: false,
            claimIntervalImpairmentChartBoxLoading: false,
            claimIntervalProductCategoryChartBoxLoading: false
        };
    },
    mounted() {
        this.initOSSClient();
        window.addEventListener('resize', () => {
            this.chartsResize();
        });
        let today = new Date();
        today.setDate(today.getDate() - 1);
        this.navForm.startTime = '2023-09-01';
        this.navForm.endTime = formatDate(today);
        this.queryData();
    },
    methods: {
        chartsResize() {
            this.largeStyle = window.innerHeight > 714;
            setTimeout(() => {
                this.claimIntervalAmountChartBox && this.claimIntervalAmountChartBox.resize();
                this.claimIntervalImpairmentChartBox && this.claimIntervalImpairmentChartBox.resize();
                this.claimIntervalProductCategoryChartBox && this.claimIntervalProductCategoryChartBox.resize();
            }, 200);
        },
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        async isExistObject(name, options = {}) {
            try {
                await this.tmpOSSClient.head(name, options);
                this.fileFLag = true;
            } catch (error) {
                if (error.code === 'NoSuchKey') {
                    this.fileFLag = false;
                }
            }
        },
        downloadFile(fileKey, btnLoading) {
            if (this.tmpOSSClient) {
                this.isExistObject(fileKey);
                if (this.fileTime > 200) {
                    this.$message.error('文件下载失败，请稍后重试！');
                    return;
                }

                if (!this.fileFLag) {
                    setTimeout(() => {
                        this.fileTime++;
                        this.downloadFile(fileKey, btnLoading);
                    }, 3000);
                } else {
                    this.fileFLag = false;
                    this.fileTime = 0;
                    let fileUrl = this.tmpOSSClient.signatureUrl(fileKey);
                    this[btnLoading] = false;
                    location.href = fileUrl;
                }
            }
        },
        buildClaimIntervalAmountChartBox(data = {}) {
            let chartDom = document.getElementById('claimIntervalAmountChartBox');
            if (!chartDom) {
                return;
            }

            const { categories, amounts, percentages, caseCounts } = data;

            const title = {
                text: '不同索赔区间案件分布',
                show: false,
                textStyle: {
                    color: '#161616',
                    fontWeight: 'bold',
                    fontSize: '12'
                },
                left: '10%'
            };
            const legend = {
                data: [{ name: '索赔金额', icon: 'circle' }, { name: '案件数', icon: 'circle' }, { name: '索赔金额占比' }],
                right: '10%'
            };
            const xAxis = {
                type: 'category',
                data: categories
            };
            const yAxis = [
                {
                    type: 'value',
                    name: '金额（元）',
                    position: 'left'
                },
                {
                    type: 'value',
                    name: '案件数',
                    position: 'right',
                    splitLine: {
                        show: false
                    }
                },
                {
                    type: 'value',
                    show: false,
                    position: 'right',
                    name: '索赔金额占比'
                }
            ];
            const series = [
                {
                    name: '索赔金额',
                    type: 'bar',
                    data: amounts,
                    yAxisIndex: 0,
                    barMaxWidth: '28px',
                    tooltip: {
                        valueFormatter: (v) => v + ' 元'
                    }
                },
                {
                    name: '案件数',
                    type: 'bar',
                    data: caseCounts,
                    yAxisIndex: 1,
                    barMaxWidth: '28px',
                    tooltip: {
                        valueFormatter: (v) => v + ' 件'
                    }
                },
                {
                    name: '索赔金额占比',
                    type: 'line',
                    data: percentages,
                    yAxisIndex: 2,
                    label: {
                        show: false,
                        formatter: (v) => `${v.value}%`
                    },
                    tooltip: {
                        valueFormatter: (v) => v + ' %'
                    }
                }
            ];

            const tooltip = {
                trigger: 'axis'
            };
            const dataZoom = [
                {
                    type: 'slider',
                    height: '15px',
                    xAxisIndex: 0,
                    filterMode: 'none'
                }
            ];
            const option = { title, legend, xAxis, yAxis, series, tooltip, dataZoom, grid: DEFAULT_GRID_SYTLE };
            this.claimIntervalAmountChartBox = echarts.init(chartDom);
            this.claimIntervalAmountChartBox.clear();
            this.claimIntervalAmountChartBox.setOption(option);
        },
        buildClaimIntervalImpairmentChartBox(data = {}) {
            let chartDom = document.getElementById('claimIntervalImpairmentChartBox');
            if (!chartDom) {
                return;
            }

            const { categories, amounts, lossAmount, percentages, caseCounts } = data;

            const title = {
                text: '不同索赔区间案件减损金额统计',
                show: false,
                textStyle: {
                    color: '#161616',
                    fontWeight: 'bold',
                    fontSize: '12'
                },
                left: '10%'
            };
            const legend = {
                data: [{ name: '已决金额', icon: 'circle' }, { name: '减损金额', icon: 'circle' }, { name: '已决案件数', icon: 'circle' }, { name: '减损金额占比' }],
                right: '10%'
            };
            const xAxis = {
                type: 'category',
                data: categories
            };
            const yAxis = [
                {
                    type: 'value',
                    name: '金额（元）',
                    position: 'left'
                },
                {
                    type: 'value',
                    name: '案件数',
                    position: 'right',
                    splitLine: {
                        show: false
                    }
                },
                {
                    type: 'value',
                    show: false,
                    position: 'right',
                    name: '减损金额占比'
                }
            ];
            const series = [
                {
                    name: '已决金额',
                    type: 'bar',
                    data: amounts,
                    yAxisIndex: 0,
                    barMaxWidth: '28px',
                    tooltip: {
                        valueFormatter: (v) => v + ' 元'
                    }
                },
                {
                    name: '减损金额',
                    type: 'bar',
                    data: lossAmount,
                    yAxisIndex: 0,
                    barMaxWidth: '28px',
                    tooltip: {
                        valueFormatter: (v) => v + ' 元'
                    }
                },
                {
                    name: '已决案件数',
                    type: 'bar',
                    data: caseCounts,
                    yAxisIndex: 1,
                    barMaxWidth: '28px',
                    tooltip: {
                        valueFormatter: (v) => v + ' 件'
                    }
                },
                {
                    name: '减损金额占比',
                    type: 'line',
                    data: percentages,
                    yAxisIndex: 2,
                    label: {
                        show: false,
                        formatter: (v) => `${v.value}%`
                    },
                    tooltip: {
                        valueFormatter: (v) => v + ' %'
                    }
                }
            ];

            const tooltip = {
                trigger: 'axis'
            };
            const dataZoom = [
                {
                    type: 'slider',
                    height: '15px',
                    xAxisIndex: 0,
                    filterMode: 'none'
                }
            ];
            const option = { title, legend, xAxis, yAxis, series, tooltip, dataZoom, grid: DEFAULT_GRID_SYTLE };
            this.claimIntervalImpairmentChartBox = echarts.init(chartDom);
            this.claimIntervalImpairmentChartBox.clear();
            this.claimIntervalImpairmentChartBox.setOption(option);
        },
        buildClaimIntervalProductCategoryChartBox(list) {
            let chartDom = document.getElementById('claimIntervalProductCategoryChartBox');
            if (!chartDom) {
                return;
            }
            const categories = list.map((e) => e.claimInterval);

            const colors = ['#61AF33', '#429DDA', '#FE7503', '#3373CA', '#429DDA', '#BCBCBC'];
            const title = {
                show: false,
                text: '不同索赔区间商品分类案件统计',
                textStyle: {
                    color: '#161616',
                    fontWeight: 'bold',
                    fontSize: '12'
                },
                left: '10%'
            };
            const legend = {
                data: [
                    { name: '医药保健', icon: 'circle', itemStyle: { color: colors[0] } },
                    { name: '酒', icon: 'circle', itemStyle: { color: colors[1] } },
                    { name: '家电', icon: 'circle', itemStyle: { color: colors[2] } },
                    { name: '3c数码', icon: 'circle', itemStyle: { color: colors[3] } },
                    { name: '家居用品', icon: 'circle', itemStyle: { color: colors[4] } },
                    { name: '其他', icon: 'circle', itemStyle: { color: colors[5] } }
                ],
                right: '10%'
            };

            const value0 = list.map((e) => (e.data.find((v) => v.goodsCategory == '医药保健') || {}).caseCount || 0);
            const value1 = list.map((e) => (e.data.find((v) => v.goodsCategory == '酒') || {}).caseCount || 0);
            const value2 = list.map((e) => (e.data.find((v) => v.goodsCategory == '家电') || {}).caseCount || 0);
            const value3 = list.map((e) => (e.data.find((v) => v.goodsCategory == '3c数码') || {}).caseCount || 0);
            const value4 = list.map((e) => (e.data.find((v) => v.goodsCategory == '家居用品') || {}).caseCount || 0);
            const value5 = list.map((e) => (e.data.find((v) => v.goodsCategory == '其他') || {}).caseCount || 0);

            const rate0 = list.map((e) => (e.data.find((v) => v.goodsCategory == '医药保健') || {}).caseRate || 0);
            const rate1 = list.map((e) => (e.data.find((v) => v.goodsCategory == '酒') || {}).caseRate || 0);
            const rate2 = list.map((e) => (e.data.find((v) => v.goodsCategory == '家电') || {}).caseRate || 0);
            const rate3 = list.map((e) => (e.data.find((v) => v.goodsCategory == '3c数码') || {}).caseRate || 0);
            const rate4 = list.map((e) => (e.data.find((v) => v.goodsCategory == '家居用品') || {}).caseRate || 0);
            const rate5 = list.map((e) => (e.data.find((v) => v.goodsCategory == '其他') || {}).caseRate || 0);

            const xAxis = {
                type: 'category',
                data: categories
            };
            const yAxis = [
                {
                    type: 'value',
                    name: '案件量',
                    position: 'left'
                }
            ];
            const series = [
                {
                    name: '其他',
                    type: 'bar',
                    data: value5,
                    barMaxWidth: 40,
                    stack: 'a',
                    itemStyle: {
                        color: colors[5]
                    }
                },
                {
                    name: '家居用品',
                    type: 'bar',
                    data: value4,
                    barMaxWidth: 40,
                    stack: 'a',
                    itemStyle: {
                        color: colors[4]
                    }
                },
                {
                    name: '3c数码',
                    type: 'bar',
                    data: value3,
                    barMaxWidth: 40,
                    stack: 'a',
                    itemStyle: {
                        color: colors[3]
                    }
                },
                {
                    name: '家电',
                    type: 'bar',
                    data: value2,
                    barMaxWidth: 40,
                    stack: 'a',
                    itemStyle: {
                        color: colors[2]
                    }
                },
                {
                    name: '酒',
                    type: 'bar',
                    data: value1,
                    barMaxWidth: 40,
                    stack: 'a',
                    itemStyle: {
                        color: colors[1]
                    }
                },
                {
                    name: '医药保健',
                    type: 'bar',
                    data: value0,
                    barMaxWidth: 40,
                    stack: 'a',
                    itemStyle: {
                        color: colors[0]
                    }
                }
            ];
            const dataZoom = [
                {
                    type: 'slider',
                    height: '15px',
                    xAxisIndex: 0,
                    filterMode: 'none'
                }
            ];
            const tooltip = {
                trigger: 'axis',
                formatter: (v) => {
                    const categories = v.map((v) => v.seriesName);
                    const values = v.map((v) => v.value);
                    const label = v[0].axisValueLabel;
                    const sum = values.reduce((acc, v) => acc + v, 0);
                    const items = list.find((e) => e.claimInterval == label).data;
                    console.log(items);
                    const v0 = (items.find((v) => v.goodsCategory == '医药保健') || {}).caseCount || 0;
                    const v1 = (items.find((v) => v.goodsCategory == '酒') || {}).caseCount || 0;
                    const v2 = (items.find((v) => v.goodsCategory == '家电') || {}).caseCount || 0;
                    const v3 = (items.find((v) => v.goodsCategory == '3c数码') || {}).caseCount || 0;
                    const v4 = (items.find((v) => v.goodsCategory == '家居用品') || {}).caseCount || 0;
                    const v5 = (items.find((v) => v.goodsCategory == '其他') || {}).caseCount || 0;

                    const r0 = (items.find((v) => v.goodsCategory == '医药保健') || {}).caseRate || 0;
                    const r1 = (items.find((v) => v.goodsCategory == '酒') || {}).caseRate || 0;
                    const r2 = (items.find((v) => v.goodsCategory == '家电') || {}).caseRate || 0;
                    const r3 = (items.find((v) => v.goodsCategory == '3c数码') || {}).caseRate || 0;
                    const r4 = (items.find((v) => v.goodsCategory == '家居用品') || {}).caseRate || 0;
                    const r5 = (items.find((v) => v.goodsCategory == '其他') || {}).caseRate || 0;

                    return `
                        <div>
                            ${label}
                            <table>
                                <tr>
                                    <td style="color:${colors[0]};font-size:40px;">&bull;&nbsp;</td>
                                    <td width="100px">${categories[5]}</td>
                                    <td width="100px" style="font-weight:bold;text-align:right">${v0} 件</td>
                                    <td width="100px" style="font-weight: bold;text-align:right"">${r0} %</td>
                                </tr>
                                <tr>
                                    <td style="color:${colors[1]};font-size:40px;">&bull;&nbsp;</td>
                                    <td width="100px">${categories[4]}</td>
                                    <td width="100px" style="font-weight:bold;text-align:right">${v1} 件</td>
                                    <td width="100px" style="font-weight: bold;text-align:right"">${r1} %</td>
                                </tr>
                                <tr>
                                    <td style="color:${colors[2]};font-size:40px;">&bull;&nbsp;</td>
                                    <td width="100px">${categories[3]}</td>
                                    <td width="100px" style="font-weight:bold;text-align:right">${v2} 件</td>
                                    <td width="100px" style="font-weight: bold;text-align:right"">${r2} %</td>
                                </tr>
                                <tr>
                                    <td style="color:${colors[3]};font-size:40px;">&bull;&nbsp;</td>
                                    <td width="100px">${categories[2]}</td>
                                    <td width="100px" style="font-weight:bold;text-align:right">${v3} 件</td>
                                    <td width="100px" style="font-weight: bold;text-align:right"">${r3} %</td>
                                </tr>
                                <tr>
                                    <td style="color:${colors[4]};font-size:40px;">&bull;&nbsp;</td>
                                    <td width="100px">${categories[1]}</td>
                                    <td width="100px" style="font-weight:bold;text-align:right">${v4} 件</td>
                                    <td width="100px" style="font-weight: bold;text-align:right"">${r4} %</td>
                                </tr>
                                <tr>
                                    <td style="color:${colors[5]};font-size:40px;">&bull;&nbsp;</td>
                                    <td width="100px">${categories[0]}</td>
                                    <td width="100px" style="font-weight:bold;text-align:right">${v5} 件</td>
                                    <td width="100px" style="font-weight: bold;text-align:right"">${r5} %</td>
                                </tr>
                            </table>
                        </div>
                    `;
                }
            };
            const option = { title, legend, xAxis, yAxis, series, tooltip, dataZoom, grid: { ...DEFAULT_GRID_SYTLE, left: '50px' } };
            this.claimIntervalProductCategoryChartBox = echarts.init(chartDom);
            this.claimIntervalProductCategoryChartBox.clear();
            this.claimIntervalProductCategoryChartBox.setOption(option);
        },
        startTimeChange() {
            this.pickerOptions2.disabledDate = (time) => {
                // 昨天
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return time.getTime() > yesterday || time.getTime() < getTimeStamp(this.navForm.startTime);
            };
        },
        endTimeChange() {
            this.pickerOptions1.disabledDate = (time) => {
                // 昨天
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return time.getTime() > getTimeStamp(this.navForm.endTime) || time.getTime() < getTimeStamp('2023-06-01');
            };
        },
        queryData() {
            this.getChart1();
            this.getChart2();
            this.getChart3();
        },
        getChart1() {
            this.claimIntervalAmountChartBoxLoading = true;
            this.instance.post('/tpa/api/dashboard/claim/interval/claimIntervalAmountDistribution', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    const list = data.data.data.list;
                    this.buildClaimIntervalAmountChartBox({
                        categories: list.map((e) => e.claimInterval),
                        amounts: list.map((e) => parseFloat(e.claimAmount)),
                        percentages: list.map((e) => e.claimAmountRate),
                        caseCounts: list.map((e) => e.caseCount)
                    });
                }
                this.claimIntervalAmountChartBoxLoading = false;
            });
        },
        getChart2() {
            this.claimIntervalImpairmentChartBoxLoading = true;
            this.instance.post('/tpa/api/dashboard/claim/interval/claimIntervalLossAmount', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    const list = data.data.data.list;

                    this.buildClaimIntervalImpairmentChartBox({
                        categories: list.map((e) => e.claimInterval),
                        amounts: list.map((e) => parseFloat(e.decidedAmount.replaceAll(',', ''))),
                        lossAmount: list.map((e) => parseFloat(e.lossAmount.replaceAll(',', ''))),
                        percentages: list.map((e) => e.lossAmountRate),
                        caseCounts: list.map((e) => e.decidedCaseCount)
                    });
                }
                this.claimIntervalImpairmentChartBoxLoading = false;
            });
        },
        getChart3() {
            this.claimIntervalProductCategoryChartBoxLoading = true;
            this.instance.post('/tpa/api/dashboard/claim/interval/goodsCategoryLossAmount', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    const list = data.data.data.list;
                    this.buildClaimIntervalProductCategoryChartBox(list);
                }
                this.claimIntervalProductCategoryChartBoxLoading = false;
            });
        },
        downloadXlsx(type) {
            let instanceUrl = '';
            let instanceForm = this.navForm;
            this.fileFLag = false;
            switch (type) {
                case 1:
                    instanceUrl = '/tpa/api/dashboard/claim/interval/claimIntervalAmountDistribution/excel';
                    break;
                case 2:
                    instanceUrl = '/tpa/api/dashboard/claim/interval/claimIntervalLossAmount/excel';
                    break;
                case 3:
                    instanceUrl = '/tpa/api/dashboard/claim/interval/goodsCategoryLossAmount/excel';
                    break;
                default:
                    break;
            }
            this['download' + type] = true;
            this.instance.post(instanceUrl, instanceForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.downloadFile(data.data, 'download' + type);
                } else {
                    this.$message.error(data.message);
                }
            });
        }
    }
};
